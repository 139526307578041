import React from 'react'
import { Button, Modal, Flex,Text } from '@metaswap/uikit'
import ModalActions from 'components/ModalActions'
import { useTranslation } from 'contexts/Localization'


// Ranking

interface ClaimRewardModalProps {
  onDismiss: () => void
  onConfirm:()=>void
  claimInfo: string
  title?: string
  confirmText?:string
}

const ClaimRewardModal: React.FC<ClaimRewardModalProps> = ({ onDismiss ,onConfirm,claimInfo,title,confirmText}) => {
  const { t } = useTranslation()
  const handleConfirm = ()=>{
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onConfirm()
    onDismiss()
  }
  return (
    <Modal maxWidth="400px!important" title={title?t(title):t('NFT Market Try-Out Rewards!')} onDismiss={onDismiss}>
    <Text dangerouslySetInnerHTML={{__html:claimInfo}}/>
      <ModalActions>
        <Flex justifyContent="space-around">
          <Button width="45%" as="a" onClick={onDismiss}>{t('Cancel')}</Button>
          <Button width="45%" onClick={handleConfirm}>{confirmText?t(confirmText):t('Confirm')}</Button>
        </Flex>
      </ModalActions>
    </Modal>
  )
}

export default ClaimRewardModal
