import { Currency, CurrencyAmount, currencyEquals, ETHER, Token } from '@metaswap/sdk'
import React, { CSSProperties, MutableRefObject, useCallback, useMemo, useState } from 'react'
import { FixedSizeList } from 'react-window'
import styled from 'styled-components'
import { Text, Flex, IconButton } from '@metaswap/uikit'
import useToast from 'hooks/useToast'
import { addOrDelCollectToken } from 'utils/api'
import { useActiveWeb3React } from '../../hooks'
import { useSelectedTokenList, WrappedTokenInfo, listToTokenMap } from '../../state/lists/hooks'
import { useAddUserToken, useRemoveUserAddedToken } from '../../state/user/hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import { LinkStyledButton } from '../Shared'
import { useIsUserAddedToken } from '../../hooks/Tokens'
import Column from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { MouseoverTooltip } from '../Tooltip'
import { AddFaviteIcon, UnFaviteIcon } from '../Svg/index'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { isTokenOnList } from '../../utils'
import default_list_tokens from '../../constants/token/babyswap.json'

function currencyKey(currency: Currency): string {
  return currency instanceof Token ? currency.address : currency === ETHER ? 'ETHER' : ''
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

const Tag = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary};
  color: ${({ theme }) => theme.colors.textSubtle};
  font-size: 14px;
  border-radius: 4px;
  padding: 0.25rem 0.3rem 0.25rem 0.3rem;
  max-width: 6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  justify-self: flex-end;
  margin-right: 4px;
`

function Balance({ balance }: { balance: CurrencyAmount }) {
  return <StyledBalanceText title={balance.toExact()}>{balance.toSignificant(4)}</StyledBalanceText>
}

const TagContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`
interface BabyCurrency extends Currency {
  address?: string
}

interface FavListProps {
  decimal: number
  tokenAddress: string
  tokenSymbol: string
}

function TokenTags({ currency }: { currency: BabyCurrency }) {
  if (!(currency instanceof WrappedTokenInfo)) {
    return <span />
  }

  const { tags } = currency
  if (!tags || tags.length === 0) return <span />

  const tag = tags[0]

  return (
    <TagContainer>
      <MouseoverTooltip text={tag.description}>
        <Tag key={tag.id}>{tag.name}</Tag>
      </MouseoverTooltip>
      {tags.length > 1 ? (
        <MouseoverTooltip
          text={tags
            .slice(1)
            .map(({ name, description }) => `${name}: ${description}`)
            .join('; \n')}
        >
          <Tag>...</Tag>
        </MouseoverTooltip>
      ) : null}
    </TagContainer>
  )
}

function CurrencyRow({
  currency,
  onSelect,
  onUpdate,
  isSelected,
  otherSelected,
  style,
  favTokens,
}: {
  currency: any
  onSelect: () => void
  onUpdate: () => void
  favTokens: FavListProps[]
  isSelected: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const { account, chainId } = useActiveWeb3React()
  const key = currency.address
  const { toastError } = useToast()
  // const selectedTokenList = useSelectedTokenList()
  const selectedTokenList = listToTokenMap(default_list_tokens)
  const isOnSelectedList = isTokenOnList(selectedTokenList, currency)
  const customAdded = useIsUserAddedToken(currency)
  const balance = useCurrencyBalance(account ?? undefined, currency)
  const [isShow, setIsShow] = useState(false)
  const removeToken = useRemoveUserAddedToken()
  const addToken = useAddUserToken()

  const preKey = useMemo(() => {
    let name = 'baby'
    if (currency.logoURI && currency.logoURI.indexOf('coingecko') > -1) {
      name = 'cg'
    } else if (currency.logoURI && currency.logoURI.indexOf('coinmarketcap') > -1) {
      name = 'cmc'
    }
    return name
  }, [currency])
  
  const foundByName = useMemo(() => {
    let name = 'Found by address'
    if (currency.logoURI && currency.logoURI.indexOf('coingecko') > -1) {
      name = 'via CoinGecko'
    } else if (currency.logoURI && currency.logoURI.indexOf('coinmarketcap') > -1) {
      name = 'via CoinMarketCap'
    }
    return name
  }, [currency])
  const addedByName = useMemo(() => {
    let name = 'Add by users'
    if (currency.logoURI && currency.logoURI.indexOf('coingecko') > -1) {
      name = 'via CoinGecko'
    } else if (currency.logoURI && currency.logoURI.indexOf('coinmarketcap') > -1) {
      name = 'via CoinMarketCap'
    }
    return name
  }, [currency])
  // only show add or remove buttons if not on selected list
  // const hide = useCallback(() => setIsShow(false), [setIsShow])
  const isFav = favTokens.find((e) => e.tokenSymbol === currency.symbol)
  const HandleCollectFavToken = useCallback(async () => {
    if (!isFav) {
      if (favTokens.length >= 5) {
        toastError('Reached the maximum number of favorite token.')
        return
      }
    }
    if (account) {
      const params = {
        decimal: currency.decimals,
        tokenAddress: currency.address ? currency.address : 'BNB',
        tokenSymbol: currency?.symbol,
        type: isFav ? 1 : 0,
        userAddress: account,
      }
      await addOrDelCollectToken(params)
      onUpdate()
    }
  }, [isFav, currency, account, onUpdate, favTokens, toastError])
  return (
    <MenuItem
      key={`${preKey}-${key}`}
      style={style}
      isBaby={currency?.symbol==='BABY'}
      className={`token-item-${key}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      onMouseLeave={() => {
        setIsShow(false)
      }}
      selected={otherSelected}
    >
      <CurrencyLogo currency={currency} size="24px" />
      <Column>
        <Flex alignItems="center">
          <Text title={currency.name}>{currency.symbol}</Text>
          {currency.name && (
            <Text color="#8F95A8" fontSize="12px" ml="10px">
              {currency.name}
            </Text>
          )}
        </Flex>

        <FadedSpan>
          {!isOnSelectedList && customAdded ? (
            <Text fontSize='12px'>
              {addedByName}
              <LinkStyledButton
                onClick={(event) => {
                  event.stopPropagation()
                  if (chainId && currency instanceof Token) removeToken(chainId, currency.address)
                }}
              >
                (Remove)
              </LinkStyledButton>
            </Text>
          ) : null}
          {(!isOnSelectedList && !customAdded) ? (
            <Text fontSize='12px'>
              {foundByName} 
              <LinkStyledButton
                onClick={(event) => {
                  event.stopPropagation()
                  if (currency instanceof Token) addToken(currency)
                }}
              >
                (Add)
              </LinkStyledButton>
            </Text>
          ) : null}
          {currency.from && <Text>via {currency.from}</Text>}
        </FadedSpan>
      </Column>
      <TokenTags currency={currency} />

      {!currency.from && (
        <RowFixed style={{ justifySelf: 'flex-end' }}>
          {balance ? <Balance balance={balance} /> : account ? <Loader /> : null}
        </RowFixed>
      )}
      {currency.from && !customAdded && (
        <LinkStyledButton
          onClick={(event) => {
            event.stopPropagation()
            if (currency instanceof WrappedTokenInfo) addToken(currency)
          }}
        >
          (Add)
        </LinkStyledButton>
      )}
      {currency.from && !!customAdded && (
        <LinkStyledButton
          onClick={(event) => {
            event.stopPropagation()
            if (currency instanceof WrappedTokenInfo) removeToken(56, currency.address)
          }}
        >
          (remove)
        </LinkStyledButton>
      )}
      {currency?.symbol === 'BABY' ? (
        <></>
      ) : (
        <TagContainer
          onClick={(e) => {
            e.stopPropagation()
            HandleCollectFavToken()
          }}
        >
          {isFav ? <AddFaviteIcon color="#E89D39" /> : <UnFaviteIcon color="#E89D39" />}
        </TagContainer>
      )}
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  currencies,
  expandCurrencies,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  onUpdate,
  fixedListRef,
  favTokenList,
  showETH,
}: {
  height: number
  currencies: Currency[]
  expandCurrencies: Currency[]
  favTokenList: FavListProps[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  onUpdate: () => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showETH: boolean
}) {
  const itemData = useMemo(() => {
    const list = showETH ? [Currency.ETHER, ...currencies, ...expandCurrencies] : [...currencies, ...expandCurrencies];
    return list.filter((arr, index) => list.findIndex(f => f.symbol === arr.symbol) === index)
  }, [currencies, showETH, expandCurrencies])

  const Row = useCallback(
    ({ data, index, style }) => {
      const currency: Currency = data[index]
      const isSelected = Boolean(selectedCurrency && currencyEquals(selectedCurrency, currency))
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, currency))
      const handleSelect = () => onCurrencySelect(currency)
      const updateFavList = () => onUpdate()
      return (
        <CurrencyRow
          style={style}
          currency={currency}
          isSelected={isSelected}
          onSelect={handleSelect}
          onUpdate={updateFavList}
          favTokens={favTokenList}
          otherSelected={otherSelected}
        />
      )
    },
    [onCurrencySelect, onUpdate, otherCurrency, selectedCurrency, favTokenList]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
