


export default {
  title: 'Diamond Hand - December 2023',
  contentInfo: `Diamond Hand - December 2023 is here! We took a snapshot on December 20th and located 150 Diamond Hands. Check if you are eligible and claim your NFB! HODL strong, babies!`,
}

export const whiteList = [
  "0xcd03fed263016861f906b1da9e8cd7d01aeaad41",
  "0xcbe7b755a6a5943cf64a8b02bbc4f7663067acb9",
  "0x64bbd415477825825602373232121a3034a20587",
  "0x3d9973116ef1796c70a75283ed00a79a86451b3f",
  "0xc530887d5a4da6c2c751b48b970f41236e67afb8",
  "0x71b9eeaffcadbd280f76b04b633b27766af7c36d",
  "0x230aed09d2a026a0d8c3fccfccd95ff466264457",
  "0xf915d7539377cd93795614e72175ab2682bf3fd3",
  "0xdd28988dc2b330a9245694b5563d8c608836397d",
  "0x10cdc6a187e9a2d54150d394a8098f8737b5e71c",
  "0x5e548421be523a5c44bf8b89c56bc38baf872734",
  "0x83df1f63d43544c40b3cfcefd2d6f476e0292215",
  "0x4e3fd5cf6db94bc20b81057df931f65dac469eff",
  "0x47799990fdd06e1a486148bd7fbc0321d9410d6b",
  "0xde78eb695f0f733bdda3ffbd96ec66d7f6b8e2e8",
  "0xc057561976b2f577908fb8ad2490838b677da56b",
  "0x3d0119b18ccf10495ec635f5d6f705ce5be5dd69",
  "0xc72540f5f3fc127b58eaf755f93007152a257db7",
  "0xb0542103cfefb2f102f66b60b0d1dac37142b466",
  "0x22d96661d5bb1eefc999b8209b5029b8490d5bc9",
  "0x2847d4ca7cf44dfda9ce9029956867156afd71bd",
  "0x4ffc102f94af3faccf794cf4daefe88066d1ecf3",
  "0x1216f86244dcf6d58aa13013409e108bcf5c6191",
  "0x384cd6431978f80e7e93043cfac17f76df47359b",
  "0x626bd3ecf63e48e918e3d94ab4b5bf274c7126c4",
  "0xd7f6540bed3eecb44239b11ef11ff5a6b078140a",
  "0x49ab2318e4537fd43d2408465bf9e5a66944c9fa",
  "0x768f2a7ccdfde9ebdfd5cea8b635dd590cb3a3f1",
  "0x30a1e0723bf3ad7ba6c9fbb89c02b2261f1996e1",
  "0x4294e1a6c116b3f7e77ad15375e865d314f90c75",
  "0x21c5315b6ee6883d945325bbcd1124a2c6b47ba6",
  "0xaf98c636cf33afae69493fd77c74bd74103fa373",
  "0xe0ed207cf0366458e3dde2e48255cca2248f8063",
  "0x1e9e88bed9a6bdd1ee05dde9bf612f447221aa3b",
  "0xb589a590183d5e1342cda9872fbd914905f5dc45",
  "0x84607c67a5a8ba54f2265e6ca9328b243c4919ee",
  "0x4353a7dd98ca6fe19b4027bcc95afe76e8ea0785",
  "0xdd68d9b1c752082b00192f9ecb79015164b1e96c",
  "0xe9e39759d73fc4e1d8d6cbda035c05ffd41e39f7",
  "0xdc8c6cedf4fe04e4149465ce0cd7299934228da7",
  "0xe424297f56d92e91045a7b8ee6d409650f0fef77",
  "0x6439af748eebcf5468156efe357cac92e91f6240",
  "0x52a258ed593c793251a89bfd36cae158ee9fc4f8",
  "0xea02dbfea42f21b4de51a49b788a2a178e9102d5",
  "0xd91c82e0d1d44c235b5024c404bcf79caeeb2586",
  "0x2b4970a6d946ecb7cb878ce9dae5d9764361cfa7",
  "0xe65936ad64282e890b0a7e34affb4f5eb1bce94e",
  "0xda9873a099770faf57b79ed5e9fa9296659e9354",
  "0xf6688fcb8272a2947c4d9b3b674d0627fee30188",
  "0x959fa27b97259250e966e77ce077d37d6c85202e",
  "0x1f3e2233d4f251c9b878170d01f1e00e66b1314e",
  "0xbbb5a6ccae2691e7a0fc94b5a548b987188aa2ff",
  "0xbbde6c6c542948d673c5cd3d1be9b4ce7d866236",
  "0x2cc6614b2cf672ffcaf55cc1d146538d87659755",
  "0x05136ce788a664e6254fb83831ba54a3f614b459",
  "0xad0ed592c9161f231123f728a04505163c552051",
  "0xd06dc69d3c99974e11dfe52ac232ac7e02051d14",
  "0x41444f247888bb078743bb7db35361fe620698cd",
  "0xde98de29b7c21e22219bf27908e149736d86a868",
  "0xd4883aab0ccf571a88bb2a48a3dd7fbecff06e0d",
  "0x4253179d265d68a810a1a0d43f3c667a8c6edaa8",
  "0x180fe1f9b738d78b22f1ceaaae62eeec6e3172a9",
  "0x5c0fa7ba7675d33f409a9a703645614f12055abf",
  "0x2b30917a9cec360ec266b77a3fb6ea5de046b0c2",
  "0x49ef37e468643c32d48fc5f63ea9e780f8e8f6da",
  "0xd1794080ceb6f5dcc514c7421e0b4c61f2d75456",
  "0xd7646e833d3ff30ee01ad756117c5455d0100e12",
  "0x5a9bd63a46eec9a42062279a18fc7fb603fb07c2",
  "0xf88b5ca03396bc68d075c1829680989236c4fe9c",
  "0x6d16960903012f2539d62dbc9f3c54537d105548",
  "0x1d943a84b01137a568965fa8577b49c75b00c29c",
  "0x81e8f310787a6406aa9d84fb3abea8aecb550a86",
  "0x445c35b728236bdb63d25f7e3bd4c1687e34baec",
  "0xa04edbfd56e230bfbd84b65076fb2dec3c2b1db3",
  "0x55105ac04d7e257422df344d101c6b0a1882c4be",
  "0x8ced5fa94a3e368734e990639f512eec8493b439",
  "0x1edb83d8288f886d8b3d24f4118e762be3c629cd",
  "0xb8cc1bb6a52f794a4530ef6a415e4ac400db208c",
  "0x4997076ba0314fd951131bdd3a2fee78de9a8ab4",
  "0xc7214f70ef185e028af57cecf155f3d3774ba5a1",
  "0xf70325352700c59bebd80e8d9d2b34db53301982",
  "0xe078ed888f4b6b6e8915b4a6a24c44bedf71ef32",
  "0x856b36a8064064823eea8571bbf67f8a3b4344ce",
  "0xd85d227f27cd60271436f99257ff1eeab9d3d589",
  "0x03c88d0919abd92e478439c5152e422444671ca0",
  "0x62ddd5cf099c7e9d984df291dff6590d61068f02",
  "0x779096b0e3bedae0aefbaa66b640076263ebb57b",
  "0x470153af94424bb57872f598d8c6f0f8e709f9e2",
  "0x0eb9173e0f9ff2218ee289d001febd85968dc0f6",
  "0xdeb75ee3d759bdef650b6ecd041bab7a47cc792c",
  "0x253e87e61db0756b3d492519125fe754d64b35d2",
  "0xec68a0ad138cf67547502e74924885ef88473acf",
  "0x1d8669c51d83e7f9d3299ba9828842a59f566fea",
  "0xb925283513eb8345b1efb9cb72660ff733fd7f2f",
  "0x50b67aab8a8e88994cfd74d5909503cd58c67578",
  "0x699f12f47b4e338e93407b4c5262537a96de2e5d",
  "0xf0c8b8f7eb7eaf8f63dcf94b41ffb1d35e24c37f",
  "0xc8707d4e31bd93e4a4bf67138c20f18652aecc43",
  "0xdc2d929486657537e088bd086032803f4e0a6d4b",
  "0x960a5564a050ccff738f4de7a644f4945b331fbd",
  "0xc6debaf1db43d4bfc19351d0134a27856b85cce3",
  "0x36d662d9645623371a8bb11160df1dbdac320450",
  "0xf6b2a6727a993914a002e5b3be864873e31894b4",
  "0x42c1ecde37c6379cb58da54105a618cfdea8cf95",
  "0x3613df048ff2c3c7ac658b34557ec70a7087002b",
  "0xb356ad7446d78158050c185ea7804bd080087a3c",
  "0x1bc67ac01ad7c79f9ec9f370b99544928204aa27",
  "0x82794da0d1e3d01e190cc59537ac36ba6bfa1415",
  "0xee6e3532bb36dce3856d543fee87fc33cce25169",
  "0xc8ead89afc14881a52f92dc6afe4efec7e73f68f",
  "0x96f310288491849b6c9e837cc25c6e9f913e4b89",
  "0x1f240e3f4b509b23dc5b8294ddb46e843351d018",
  "0x983e115ef4c852d1900eb59e4324360591351c25",
  "0xb3c80b717e32918911c8b9345629689a0f6e5817",
  "0xe922f733fbeb6edbe96894546e0f824dc97edc55",
  "0x56650398ba056be6dbb39b7d0f81e6b97e05bb0c",
  "0x438066b8897e371c35b35b9afbd18220d9c0ebe7",
  "0x0a71fd71689a9052a6c6e07bd208c121c576d4a9",
  "0x183c9be3f315a0a2b304b3ee34258a9b4a3f7d7c",
  "0x1f69d390d37def47c67d1ec32573f980d1859ab2",
  "0xcce42d77e72301b710eabe71eab94073d9f8d619",
  "0x215478786062c4ae1218d81a0bb8e49ccc1efe06",
  "0xc57ec30670d6964360432452f09dc60fdc5d6517",
  "0x89021dadd64b30623564cad96f5f8e75bdfd0695",
  "0x5c16e4fb00544af77170457eb0b207d1e4a04d35",
  "0x3683fd786cd8974b93a610f374c7cb48704ecb30",
  "0xb650d2a532ca3194298cb0cb613c587ac9495212",
  "0x0c2db12970e7544849408fe87447a34e4a18ec3a",
  "0xb4017353480302b1cce31515c1af0707bb8b7206",
  "0x6834532b854a5c91c06e6252658a6703834a5799",
  "0xc7f70dbb083a86260d169c366d9f11cb4bc7f424",
  "0x1c89650a1e702339f4c33bdba7b5cfa9201cbda3",
  "0x08d4a272b3188646959608decb7f9128f9f6587d",
  "0x5afef04e9ab31049d6a4922d392191c1a6ef8920",
  "0x5c588a62716d71210f70ad793f15ffb65f86933f",
  "0x635510eb75c0ce0c651f88e37f34b2cbfc167998",
  "0x72c1a4d6c153221325be2003b08212822df84bf2",
  "0xb8bbe06352dc547a365867c80de1d7f2cb2c361c",
  "0xe03e66e4a6a85f5a192955ffdf6d7385873b1ef2",
  "0x343f98bf2bca4c36705f990d896f5387a13fe0fc",
  "0x6d5526337f0c16fa9513ec762a27330cdf44448c",
  "0x743180420e732fe6eee83f4906181495e6769700",
  "0xcab86a2f13691739a758bdb36d750a08ce3dc411",
  "0xd37479473aa0fd42f371e21a6d88609843cff218",
  "0x179d8e9c9c65e26f517fe087e296e83d8311d1c9",
  "0xf50f87f386805aa8123b8afa5511e446612ff5b1",
  "0xf17968bbe3afd615414f4058110f831fbcad9d04",
  "0x4aa9bd31e68417f40a33e11ec23e8663c86dc681",
  "0x399e5a91c2755394ba4366f22f79bbdc63703db8",
  "0x1c4bd30952bee3a331073089f808dc58a46b948c",
]

