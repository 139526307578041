import React, { useEffect,useCallback,useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import Web3 from 'web3'
import getRpcUrl from 'utils/getRpcUrl'
import * as SIDfunctions from "@siddomains/sidjs";
// @ts-ignore
import SID from "@siddomains/sidjs";





 const useSpaceId= ()=>{
    const { account } = useWeb3React()
    const [spaceName, setSpaceName] = useState<string>('')
    const fetchUserInfo = useCallback(async() => {
     
        const infura = getRpcUrl()||'https://bsc-dataseed1.ninicoin.io'
        const provider = new Web3.providers.HttpProvider(infura);
        const sid = new SID({
          provider,
          sidAddress: SIDfunctions.getSidAddress("56")
        });
        const domain = await sid.getName(account); 
        console.info(domain,'domain')
        if(domain.name){
            setSpaceName(domain.name)
        }else{
            setSpaceName('')
        }
      }, [account])

    useEffect(()=>{
        if(account){
            fetchUserInfo()
          }
    },[account,fetchUserInfo])
    return spaceName
}

export default useSpaceId