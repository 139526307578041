import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../../index'
import {
  hidePhishingWarningBanner
} from '../actions'


// eslint-disable-next-line import/prefer-default-export
export function usePhishingBannerManager(): [boolean, () => void] {
  const dispatch = useDispatch<AppDispatch>()
  const showPhishingWarningBanner = useSelector<AppState, AppState['user']['showPhishingWarningBanner']>(
    (state) => state.user.showPhishingWarningBanner,
  )
  const hideBanner = useCallback(() => {
    dispatch(hidePhishingWarningBanner())
  }, [dispatch])

  return [showPhishingWarningBanner, hideBanner]
}
