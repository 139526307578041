import React from 'react'
import styled ,{ useTheme } from 'styled-components'
import { Card } from '@metaswap/uikit'

export const BodyWrapper = styled(Card)`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;
  overflow: initial;
  padding:10px ;
  > div {
    background-color:${({ theme }) => theme.colors.backgroundAlt};
    /* border-radius: 30px; */
  }
`
const SwapImage = styled.img`
  position: absolute;
  top: 12px;
  left: -30%;
  z-index: -1;
  display: none;
  width: 200px;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  const theme = useTheme()
  const src = theme.isDark ? '/images/baby-dark.png' : '/images/baby.png'
  return <BodyWrapper>
    {/* <SwapImage src={src} alt="Swap" /> */}
    {children}
  </BodyWrapper>
}
