import React from 'react'
import { Modal } from '@metaswap/uikit'
import { ContextApi } from 'contexts/Localization/types'
import SlippageToleranceSetting from './SlippageToleranceSetting'
import TransactionDeadlineSetting from './TransactionDeadlineSetting'

type SettingsModalProps = {
  onDismiss?: () => void,
  t: ContextApi['t']
}

// TODO: Fix UI Kit typings
const defaultOnDismiss = () => null

const SettingsModal = ({ onDismiss = defaultOnDismiss, t }: SettingsModalProps) => {
  return (
    <Modal title={t('Settings')} onDismiss={onDismiss}>
      <SlippageToleranceSetting t={t}/>
      <TransactionDeadlineSetting t={t}/>
    </Modal>
  )
}

export default SettingsModal
