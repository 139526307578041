// import { BASE_EXCHANGE_URL } from 'config'

export const defaultTrendingTokenSrc = 'https://baby-upload.s3.amazonaws.com/images/coins/%3F.png';

export const trendingJumpToSwap = (token) => {
  const swapUrl =  `/#/swap?outputCurrency=${token.contractAddress}`
}

export const decmiclNum = (priceStr = '') => {

  const [integer = '', decimal = ''] = String(priceStr).split('.');
  if (Number(integer)) {
    const n = 5 - integer.length;
    return n > 0 ? n : 0;
  }
  const pStr = decimal.length > 10 ? decimal.substring(0, 12) : decimal;
  let flagFirstZero = false;
  let notZeroInd = 0;
  for (let i = 0; i < pStr.length; i++) {
    if (pStr[i] !== '0' && !flagFirstZero) {
      flagFirstZero = true;
      notZeroInd = i;
    }
  }

  return notZeroInd + 5 > 12 ? 12 : notZeroInd + 5;
}