import React, { ReactNode,useState } from 'react'
import styled from 'styled-components'
import { Heading, IconButton, Text, Flex, useModal, CogIcon, Svg } from '@metaswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { TrendIcon } from 'components/Svg'
import SettingsModal from './SettingsModal'
import RecentTransactionsModal from './RecentTransactionsModal'

interface PageHeaderProps {
  title: ReactNode
  description?: ReactNode
  children?: ReactNode
  hasShow?:boolean
  showTrend?:(isShow:boolean)=>void
}

const HistoryIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M13 3C8.03 3 4 7.03 4 12H1L4.89 15.89L4.96 16.03L9 12H6C6 8.13 9.13 5 13 5C16.87 5 20 8.13 20 12C20 15.87 16.87 19 13 19C11.07 19 9.32 18.21 8.06 16.94L6.64 18.36C8.27 19.99 10.51 21 13 21C17.97 21 22 16.97 22 12C22 7.03 17.97 3 13 3ZM12 8V13L16.28 15.54L17 14.33L13.5 12.25V8H12Z"
      fill="currentColor"
    />
  </Svg>
)

const BridgeIcon = () => (
  <Svg width="24" height="24" viewBox="0 0 24 24">

<path d="M20.9999 9.4668V17.8838C21.0028 18.1757 20.8909 18.457 20.6886 18.6661C20.4862 18.8752 20.2099 18.9952 19.92 19H17.7484C17.7541 17.4522 17.1527 15.9648 16.0749 14.8609C14.9972 13.757 13.5302 13.1258 11.9928 13.1047C10.4559 13.1266 8.98962 13.7581 7.91249 14.8619C6.83537 15.9657 6.23436 17.4527 6.24002 19H4.09299C3.80291 18.9952 3.52649 18.8751 3.32413 18.6658C3.12177 18.4565 3.00991 18.1751 3.013 17.8831V9.4668C3.0101 9.1749 3.12205 8.8937 3.32439 8.68458C3.52673 8.47547 3.80303 8.35542 4.09299 8.35065H19.92C20.2099 8.35542 20.4862 8.47547 20.6886 8.68458C20.8909 8.8937 21.0028 9.1749 20.9999 9.4668ZM20.6263 6.11543H3.36003C3.31209 6.11477 3.26475 6.10461 3.22072 6.08553C3.17668 6.06645 3.13681 6.03882 3.10338 6.00423C3.06995 5.96963 3.04362 5.92875 3.02588 5.88391C3.00815 5.83907 2.99937 5.79115 3.00004 5.7429V5.37254C2.99937 5.32428 3.00815 5.27636 3.02588 5.23152C3.04362 5.18668 3.06995 5.1458 3.10338 5.1112C3.13681 5.07661 3.17668 5.04898 3.22072 5.0299C3.26475 5.01082 3.31209 5.00066 3.36003 5H20.6263C20.6742 5.00066 20.7215 5.01082 20.7656 5.0299C20.8096 5.04898 20.8495 5.07661 20.8829 5.1112C20.9164 5.1458 20.9427 5.18668 20.9604 5.23152C20.9781 5.27636 20.9869 5.32428 20.9863 5.37254V5.74507C20.987 5.84216 20.9496 5.9356 20.8822 6.005C20.8147 6.07439 20.7227 6.1141 20.6263 6.11543ZM12.5472 8.34992H11.4672V6.11543H12.5472V8.34847V8.34992ZM15.965 8.34992H14.885V6.11543H15.965V8.34847V8.34992ZM19.3821 8.34992H18.3021V6.11543H19.3821V8.34847V8.34992ZM9.13008 8.34992H8.05009V6.11543H9.13008V8.34847V8.34992ZM5.71298 8.34992H4.63299V6.11543H5.71298V8.34847V8.34992Z" fill="#E89D39"/>

  </Svg>
 )

const StyledPageHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
  padding: 24px;
`

const Details = styled.div`
  flex: 1;
`

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.isDark ? '#8D9FB5' : theme.colors.text};
`

const PageHeader = ({ title, description,hasShow, children,showTrend }: PageHeaderProps) => {
  const { t } = useTranslation()
  const [isShow,setIsShow] = useState(hasShow||false)
  const [onPresentSettings] = useModal(<SettingsModal t={t} />)
  const [onPresentRecentTransactions] = useModal(<RecentTransactionsModal t={t} />)
  const openTrending = ()=>{
    if(showTrend){
      showTrend(!hasShow)
    }
    setIsShow(!isShow)}
  const goBridge = ()=>{
    window.open('https://bridge.babyswap.finance')
  }
  return (
    <StyledPageHeader>
      <Flex alignItems="center">
        <Details>
          <Heading mb="8px">{title}</Heading>
          {description && (
            <StyledText fontSize="14px">
              {description}
            </StyledText>
          )}
        </Details>
        <IconButton variant="text" onClick={openTrending} title={t('Settings')}>
          {/* <TrendIcon width="24px" color="currentColor" /> */}
        </IconButton>
        <IconButton  onClick={goBridge} variant="text" title={t('Bridge')}>
          <BridgeIcon/>
        </IconButton>
        <IconButton variant="text" onClick={onPresentSettings} title={t('Settings')}>
          <CogIcon width="24px" color="currentColor" />
        </IconButton>
        <IconButton
          variant="text"
          onClick={onPresentRecentTransactions}
          title={t('Recent transactions')}
        >
          <HistoryIcon />
        </IconButton>
      </Flex>
      {children && <Text mt="16px">{children}</Text>}
    </StyledPageHeader>
  )
}

export default PageHeader
