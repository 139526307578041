import { AbiItem } from 'web3-utils'
import { getWeb3NoAccount } from 'utils/web3'
import { FACTORY_ADDRESS, Token } from '@metaswap/sdk'
import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import { Field } from 'state/swap/actions'
import BigNumber from 'bignumber.js'
import CalculateABI from '../constants/abis/CalculateRoute.json'
import { ROUTE_LIQUIDITY_OUT, ROUTE_LIQUIDITY_IN, CALCULATE_ADDRESS } from '../constants'

interface RoutePath {
    amounts: string []
    factories: string []
    fees: string []
    pairs: string []
    path: string []
    impact: string[]
    maxOut: BigNumber
    minIn: BigNumber
    noBabyPathNum?: number
}

const findMax = (arry: RoutePath []): RoutePath | undefined  => {
    if (arry.length) {
        let current = arry[0].maxOut
        let maxIndex = 0
        arry.forEach((f, i) => {
            if (f.maxOut.gt(current)) {
                current = f.maxOut
                maxIndex = i
            }
        })
        return arry[maxIndex]
    }
    return undefined
}
const findMin = (arry: RoutePath []): RoutePath | undefined  => {
    if (arry.length) {
        let current = arry[0].minIn
        let minIndex = 0
        arry.forEach((f, i) => {
            if (f.maxOut.lt(current)) {
                current = f.minIn
                minIndex = i
            }
        })
        return arry[minIndex]
    }
    return undefined
}
const genGoodPath = (allPaths: RoutePath[], field: Field | undefined):RoutePath | undefined => {
    let filterMaxAmount:RoutePath | undefined
    if (field === Field.INPUT) {
        const amountOuts = allPaths.map(d => new BigNumber(d.maxOut))
        const maxAmountOut = BigNumber.maximum(...amountOuts)
        const filterAmount = allPaths.filter(f => f.maxOut.gte(new BigNumber(maxAmountOut).times(ROUTE_LIQUIDITY_OUT)))
        const hasBabyPath = filterAmount.some(s => s.factories.some(s1 => s1.toLocaleLowerCase() === FACTORY_ADDRESS.toLocaleLowerCase())) ?? false
        if (hasBabyPath) {
            const filterFactory = filterAmount.filter(f => f.factories.some(s => s.toLocaleLowerCase() === FACTORY_ADDRESS.toLocaleLowerCase()))
            const minPathNum = _.minBy(filterFactory, o => o.noBabyPathNum)
            const filterNoBabyPath = filterFactory.filter(f => f.noBabyPathNum === minPathNum?.noBabyPathNum)
            filterMaxAmount = findMax(filterNoBabyPath) ?? undefined
        } else {
            filterMaxAmount = findMax(allPaths)
        }
    } else if (field === Field.OUTPUT) {
        const amountIns = allPaths.map(d => d.minIn)
        const minAmountIn = BigNumber.minimum(...amountIns)
        const filterAmount = allPaths.filter(f => new BigNumber(f.amounts[0].toString()).lte(new BigNumber(minAmountIn).times(ROUTE_LIQUIDITY_IN)))
        const hasBabyPath = filterAmount.some(s => s.factories.some(s1 => s1.toLocaleLowerCase() === FACTORY_ADDRESS.toLocaleLowerCase())) ?? false
        if (hasBabyPath) {
            const filterFactory = filterAmount.filter(f => f.factories.some(s => s.toLocaleLowerCase() === FACTORY_ADDRESS.toLocaleLowerCase()))
            const minPathNum = _.minBy(filterFactory, o => o.noBabyPathNum)
            const filterNoBabyPath = filterFactory.filter(f => f.noBabyPathNum === minPathNum?.noBabyPathNum)
            filterMaxAmount = findMin(filterNoBabyPath)
        } else {
            filterMaxAmount = findMin(allPaths)
        }
    }
    return filterMaxAmount 
}

const useCalculateRoute = (
        tokenA: Token | undefined, 
        tokenB: Token | undefined,
        field: Field | undefined,
        typedValue: string | undefined
    ) => {
    const [calculateRoute, setCalculateRoute] = useState<RoutePath>()
    const fetchRoutePath = useCallback(async () => {
        if (tokenA && tokenB && typedValue) {
            const web3 = getWeb3NoAccount()
            const calculateContract = new web3.eth.Contract(CalculateABI as unknown as AbiItem, CALCULATE_ADDRESS)
            let allPaths: RoutePath[]  = []
            if (field === Field.INPUT) {
                const value = new BigNumber(typedValue).times(new BigNumber(10).pow(tokenA.decimals)).toFixed()
                allPaths = await calculateContract.methods.calculateAmountOut(tokenA.address, tokenB.address, value).call()
            } else if (field === Field.OUTPUT) {
                const value = new BigNumber(typedValue).times(new BigNumber(10).pow(tokenB.decimals)).toFixed()
                allPaths = await calculateContract.methods.calculateAmountIn(tokenA.address, tokenB.address, value).call()
            }
            // console.log("allPaths", allPaths)
            const allBabyPaths = allPaths.map(f => {
                let num = 0
                f.factories.forEach(ff => {
                    if (ff.toLocaleLowerCase() !== FACTORY_ADDRESS.toLocaleLowerCase()) {
                        num ++
                    }
                })
                return {
                    ...f, 
                    maxOut: new BigNumber(f.amounts.slice(-1)[0].toString()),
                    minIn: new BigNumber(f.amounts[0].toString()),
                    noBabyPathNum: num
                }
            })
            const filterMaxAmount = genGoodPath(allBabyPaths, field)
            // console.log("filterMaxAmount", filterMaxAmount)
            // const paths = filterMaxAmount?.factories.map(d => {
            //     return d === '0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da' ? 'BabySwap' : 'PanckeSwap'
            // })
            // console.log(`${tokenA.name} -> ${paths?.join('->')} -> ${tokenB.name}`)
            setCalculateRoute(filterMaxAmount)
        }
    }, [tokenA, tokenB, field, typedValue])

    useEffect(() => {
        fetchRoutePath()
    /* eslint-disable */
    }, [tokenA, tokenB, field, typedValue])

    return { calculateRoute, fetchRoutePath }
}

export default useCalculateRoute