import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'

export const FadedSpan = styled(RowFixed)`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 14px;
`

export const PaddedColumn = styled(AutoColumn)`
  padding: 20px;
  padding-bottom: 12px;
`
export const PaddingColumn = styled.div`
  padding: 20px;
  padding-bottom: 12px;
`

export const MenuItem = styled(RowBetween)<{isBaby?:boolean}>`
  padding: 4px 20px;
  height: 56px;
  display: grid;
  grid-template-columns:${({isBaby})=>isBaby?'auto minmax(auto, 1fr) auto minmax(0, 72px)':'auto minmax(auto,1fr) auto minmax(0,72px) minmax(0,12px);'} ;
  grid-gap: 16px;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  pointer-events: ${({ disabled }) => disabled && 'none'};
  :hover {
    background-color: ${({ theme }) => theme.isDark ? 'rgb(8, 6, 11)' : 'rgb(250, 249, 250)'};
  }
  opacity: ${({ disabled, selected }) => (disabled || selected ? 0.5 : 1)};
`

export const SearchInput = styled.input`
  position: relative;
  display: flex;
  padding: 16px;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  background: none;
  border: none;
  outline: none;
  border-radius: 20px;
  color: ${({ theme }) => theme.colors.text};
  border-style: solid;
  border: 1px solid ${({ theme }) => theme.colors.tertiary};
  -webkit-appearance: none;
  margin: 14px 0px;
  font-size: 18px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.textDisabled};
  }
  transition: border 100ms;
  :focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    outline: none;
  }
`

export const CommonTokenWrapper = styled.div`
  border-radius: 20px;
  padding: 20px;
  width:100%;
  display: grid;
  grid-template-columns: repeat(3,1fr);
  grid-template-rows: repeat(1,1fr);
  grid-gap: 20px 35px;
  @media screen and (max-width: 1020px) {
    padding: 0px;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2,1fr);
    grid-gap: 5px 25px;
  }
`

export const CommonToken = styled.div`
  display: flex;
  > img{
    width: 24px;
     height: 24px;
     margin-right:10px;
  }
`


export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.isDark ? 'none' : '#fff'};
`

export const SeparatorDark = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.tertiary};
`
