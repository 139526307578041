import { CurrencyAmount, JSBI, Token, Trade } from '@metaswap/sdk'
import BigNumber from 'bignumber.js'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { ArrowDown } from 'react-feather'
import { useSelector } from 'react-redux'
import { AppState } from 'state'
import {
  CardBody,
  Button,
  IconButton,
  Text,
  useTooltip,
  Flex,
  useMatchBreakpoints,
  Box,
  ChevronDownIcon,
} from '@metaswap/uikit'
import { SwapIcon, TrendIcon } from 'components/Svg'
import styled, { ThemeContext,useTheme } from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import AddressInputPanel from 'components/AddressInputPanel'
import Card, { GreyCard } from 'components/Card'
import { AutoColumn } from 'components/Column'
import ConfirmSwapModal from 'components/swap/ConfirmSwapModal'
import CurrencyInputPanel from 'components/CurrencyInputPanel'
import CardNav from 'components/CardNav'
import { AutoRow, RowBetween } from 'components/Row'
import AdvancedSwapDetailsDropdown from 'components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from 'components/swap/confirmPriceImpactWithoutFee'
import { ArrowWrapper, BottomGrouping, SwapCallbackError, Wrapper } from 'components/swap/styleds'
import TradePrice from 'components/swap/TradePrice'
import TokenWarningModal from 'components/TokenWarningModal'
import SyrupWarningModal from 'components/SyrupWarningModal'
import ProgressSteps from 'components/ProgressSteps'
import { getTradeMiningContract } from 'utils'
// import { useDispatch } from 'react-redux'
import { BIG_TEN, ONE_BILLION } from 'utils/bigNumber'
import { INITIAL_ALLOWED_SLIPPAGE, DEFAULT_MIN_SLIPPAGE } from 'constants/index'
import { useActiveWeb3React } from 'hooks'
import { useCurrency, useExpendTokens, useToken } from 'hooks/Tokens'
import { ApprovalState, useApproveCallbackFromTrade } from 'hooks/useApproveCallback'
import { useSwapCallback } from 'hooks/useSwapCallback'
import useWrapCallback, { WrapType } from 'hooks/useWrapCallback'
import useTradeMining from 'hooks/useTradeMining'
import { Field } from 'state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState,
  useTokenPriceSwapInfo,
} from 'state/swap/hooks'
import { useSelectedTokenList, WrappedTokenInfo } from 'state/lists/hooks'
import { useExpertModeManager, useUserDeadline, useUserSlippageTolerance, useAddUserToken } from 'state/user/hooks'
import { LinkStyledButton } from 'components/Shared'
import { maxAmountSpend } from 'utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from 'utils/prices'
import Loader from 'components/Loader'
import PageHeader from 'components/PageHeader'
// import { cgList, cmcList } from 'state/lists/actions'
import ConnectWalletButton from 'components/ConnectWalletButton'
import AppBody from '../AppBody'
import Trending from './components/trending'
import ActionSheet from './components/ActionSheet'

const StyledTrade = styled.div`
  position: relative;
  max-width: 436px;
  width: 100%;
  padding: 0.5rem 1.5rem;
  background: linear-gradient(90deg, #e89b37 0%, rgba(239, 196, 67, 0.61) 100%);
  box-shadow: 0px 3px 8px rgba(58, 126, 190, 0.09);
  border-radius: 20px;
  margin-bottom: 12px;
`

const BridgeButton = styled.a`
  background: url('images/buttonBg.png') no-repeat;
  background-size: 100% 100%;
  max-width: 436px;
  height: 50px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  color: white;
  cursor: pointer;
  & img {
    vertical-align: middle;
  }
`

const StyleTips = styled.img`
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-top: 8px;
`

const StyleSmartRouter = styled.div`
  position: relative;
  max-width: 436px;
  width: 100%;
  z-index: 5;
  overflow: initial;
  margin-bottom: 20px;
`
const SwapImage = styled.img`
  position: absolute;
  top: 12px;
  right: -40%;
  z-index: 100;
  display: none;
  ${({ theme }) => theme.mediaQueries.lg} {
    display: block;
  }
`

const Swap = () => {
  const loadedUrlParams = useDefaultsFromURLSearch()
  const { t } = useTranslation()
  const { account, chainId, library } = useActiveWeb3React()
  // token warning stuff
  const { isMobile, isMd } = useMatchBreakpoints()
  const [isShowing, setIsShowing] = useState(false)
  const [isMbShowing, setIsMbShowing] = useState(false)
  const [trendToken, setTrendToken] = useState<Token[]>()
  const [trendTokenA, setTrendTokenA] = useState<Token>()
  const [inputVal, setInputVal] = useState(0)
  const [usdtInputVal, setUsdtInputVal] = useState('')
  const [loadedInputCurrency, loadedOutputCurrency] = [
    useCurrency(loadedUrlParams?.inputCurrencyId),
    useCurrency(loadedUrlParams?.outputCurrencyId),
  ]
  const themes = useTheme()
  const src = themes.isDark ? '/images/baby-dark.png' : '/images/baby.png'
  const addToken = useAddUserToken()
  const allTokens = useSelectedTokenList()
  const expandTokens = useExpendTokens()
  const [dismissTokenWarning, setDismissTokenWarning] = useState<boolean>(false)
  const [isSyrup, setIsSyrup] = useState<boolean>(false)
  const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>((state) => state.user.audioPlay)
  // const dispatch = useDispatch()
  const [syrupTransactionType, setSyrupTransactionType] = useState<string>('')
  const urlLoadedTokens: Token[] = useMemo(() => {
    const tokenList = Object.keys(allTokens[chainId ?? 56])
    return (
      [loadedInputCurrency, loadedOutputCurrency]?.filter(
        (c): c is Token =>
          c instanceof Token && !tokenList.some((s) => s.toLocaleLowerCase() === c.address.toLocaleLowerCase())
      ) ?? []
    )
  }, [loadedInputCurrency, loadedOutputCurrency, allTokens, chainId])

  const handleConfirmSyrupWarning = useCallback(() => {
    setIsSyrup(false)
    setSyrupTransactionType('')
  }, [])

  const theme = useContext(ThemeContext)

  const [isExpertMode] = useExpertModeManager()
  const { reward, fetchReward } = useTradeMining(account)
  // const isSwapMining = Number(Math.floor(new Date().getTime() / 1000)) > 1622642400
  const isSwapMining = false

  // get custom setting values for user
  const [deadline] = useUserDeadline()
  const [userAllowedSlippage] = useUserSlippageTolerance()

  // swap state
  const { independentField, typedValue, recipient } = useSwapState()

  const {
    v2Trade,
    currencyBalances,
    parsedAmount,
    currencies,
    inputError: swapInputError,
    fees,
    factories,
    amounts,
    pairs,
    paths,
  } = useDerivedSwapInfo()
  const { amounts1 } = useTokenPriceSwapInfo()
  const tokenPrice = useMemo(() => {
    let value = 1
    if (currencies[Field.INPUT]?.symbol === 'USDT') {
      value = 1
    } else {
      value = new BigNumber(amounts1[amounts1.length - 1]).dividedBy(BIG_TEN.pow(Number(18))).toNumber()
      // console.info(value,'useTokenPriceSwapInfo',currencies[Field.INPUT],amounts1[amounts1.length-1])
    }
    return value
  }, [amounts1, currencies])
  const { wrapType, execute: onWrap, inputError: wrapInputError } = useWrapCallback(
    currencies[Field.INPUT],
    currencies[Field.OUTPUT],
    typedValue
  )
  const showWrap: boolean = wrapType !== WrapType.NOT_APPLICABLE
  const trade = showWrap ? undefined : v2Trade

  const parsedAmounts = showWrap
    ? {
        [Field.INPUT]: parsedAmount,
        [Field.OUTPUT]: parsedAmount,
      }
    : {
        [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
        [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount,
      }

  const {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onUserUsdtInput,
    onChangeRecipient,
  } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )
  const handleCurrencyExchange = () => {
    if (audioPlay) {
      const audio = document.getElementById('bgMusic') as HTMLAudioElement
      if (audio) {
        audio.play()
      }
    }
  }

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined,
  })

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: showWrap
      ? parsedAmounts[independentField]?.toExact() ?? ''
      : parsedAmounts[dependentField]?.toSignificant(6) ?? '',
  }

  const route = trade?.route
  const userHasSpecifiedInputOutput = Boolean(
    currencies[Field.INPUT] && currencies[Field.OUTPUT] && parsedAmounts[independentField]?.greaterThan(JSBI.BigInt(0))
  )
  const noRoute = !route

  // set fee token slippage
  const allowedSlippage = useMemo(() => {
    if (!trade) return userAllowedSlippage
    const tokenSlippage = DEFAULT_MIN_SLIPPAGE.find(
      (f) => f.symbol === trade?.inputAmount.currency.symbol || f.symbol === trade?.outputAmount.currency.symbol
    )
    if (tokenSlippage) {
      return userAllowedSlippage > tokenSlippage.value ? userAllowedSlippage : tokenSlippage.value
    }
    return userAllowedSlippage
  }, [trade, userAllowedSlippage])
  const [sliderPercent, setSliderPercent] = useState(0)

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade, allowedSlippage)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  // 提取交易挖矿收益
  const handleWithdrawTrade = () => {
    if (!chainId || !library || !account) throw new Error('missing dependencies')
    const tradeContract = getTradeMiningContract(chainId, library, account)
    tradeContract.takerWithdraw().then(() => {
      fetchReward()
    })
  }

  // useEffect(() => {
  //   const fetchCmcList = async () => {
  //     const resp = await fetch('https://tokens.pancakeswap.finance/cmc.json')
  //     const data = await resp.json()
  //     if (!localStorage.getItem('cmcList')) {
  //       localStorage.setItem('cmcList', JSON.stringify(data))
  //     }
  //   }
  //   const fetchCgList = async () => {
  //     const resp = await fetch('https://tokens.pancakeswap.finance/coingecko.json')
  //     const data = await resp.json()
  //     if (!localStorage.getItem('cgList')) {
  //       localStorage.setItem('cgList', JSON.stringify(data))
  //     }
  //   }
  //   fetchCmcList()
  //   fetchCgList()
  //   eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  const maxAmountInput: CurrencyAmount | undefined = maxAmountSpend(currencyBalances[Field.INPUT])
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT]?.equalTo(maxAmountInput))

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(
    trade,
    allowedSlippage,
    deadline,
    recipient,
    fees,
    factories
  )

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState((prevState) => ({ ...prevState, attemptingTxn: true, swapErrorMessage: undefined, txHash: undefined }))
    swapCallback()
      .then((hash) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: undefined,
          txHash: hash,
        }))
      })
      .catch((error) => {
        setSwapState((prevState) => ({
          ...prevState,
          attemptingTxn: false,
          swapErrorMessage: error.message,
          txHash: undefined,
        }))
      })
  }, [priceImpactWithoutFee, swapCallback, setSwapState])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !swapInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED)) &&
    !(priceImpactSeverity > 3 && !isExpertMode)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, showConfirm: false }))

    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [onUserInput, txHash, setSwapState])

  const handleAcceptChanges = useCallback(() => {
    setSwapState((prevState) => ({ ...prevState, tradeToConfirm: trade }))
  }, [trade])

  // This will check to see if the user has selected Syrup to either buy or sell.
  // If so, they will be alerted with a warning message.
  const checkForSyrup = useCallback(
    (selected: string, purchaseType: string) => {
      if (selected === 'syrup') {
        setIsSyrup(true)
        setSyrupTransactionType(purchaseType)
      }
    },
    [setIsSyrup, setSyrupTransactionType]
  )

  const handleTypeInput = useCallback(
    (value: string) => {
      if (currencies[Field.INPUT] && value && tokenPrice) {
        const usdVal = new BigNumber(value).times(tokenPrice).toFormat(12)
        console.info(usdVal, 'usdVal')
        setUsdtInputVal(String(usdVal))
      }
      if (!value || !tokenPrice) {
        setUsdtInputVal('')
      }
      onUserInput(Field.INPUT, value)
      setInputVal(Number(value) / Number(maxAmountInput?.toExact()))
    },
    [onUserInput, maxAmountInput, currencies, tokenPrice]
  )
  useEffect(() => {
    if (!usdtInputVal) {
      onUserInput(Field.INPUT, '')
    }
  }, [usdtInputVal, onUserInput])
  const handleUsdtInput = useCallback(
    (value: string) => {
      setUsdtInputVal(value)
      if (currencies[Field.INPUT] && value && tokenPrice) {
        const InputVal = new BigNumber(value).div(tokenPrice).toString()
        const inputStr = String(InputVal).substring(0, String(InputVal).indexOf('.') + 8)
        onUserInput(Field.INPUT, String(inputStr))
        setInputVal(Number(InputVal) / Number(maxAmountInput?.toExact()))
      } else {
        onUserInput(Field.INPUT, '')
        setInputVal(0)
      }
    },
    [setUsdtInputVal, onUserInput, tokenPrice, currencies, maxAmountInput]
    // [setUsdtInputVal]
  )
  const handleInputSelect = useCallback(
    (inputCurrency) => {
      setApprovalSubmitted(false) // reset 2 step UI for approvals
      onCurrencySelection(Field.INPUT, inputCurrency)
      setUsdtInputVal('')
      if (inputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(inputCurrency.symbol.toLowerCase(), 'Selling')
      }
    },
    [onCurrencySelection, setApprovalSubmitted, checkForSyrup]
  )

  const handleMaxInput = useCallback(() => {
    if (maxAmountInput) {
      onUserInput(Field.INPUT, maxAmountInput.toExact())
    }
  }, [maxAmountInput, onUserInput])

  const HandleSlideInput = useCallback(
    (v: number) => {
      if (maxAmountInput && v) {
        onUserInput(
          Field.INPUT,
          v === 100 ? maxAmountInput.toExact() : String((Number(maxAmountInput.toExact()) * v * 100) / 10000)
        )
        const val = tokenPrice ? ((Number(maxAmountInput.toExact()) * v * 100) / 10000) * tokenPrice : 0
        setUsdtInputVal(String(val))
      } else {
        onUserInput(Field.INPUT, '0')
        setUsdtInputVal('')
      }
    },
    [maxAmountInput, onUserInput, tokenPrice]
  )

  const handleOutputSelect = useCallback(
    (outputCurrency) => {
      onCurrencySelection(Field.OUTPUT, outputCurrency)
      if (outputCurrency.symbol.toLowerCase() === 'syrup') {
        checkForSyrup(outputCurrency.symbol.toLowerCase(), 'Buying')
      }
    },
    [onCurrencySelection, checkForSyrup]
  )
  const { targetRef, tooltip, tooltipVisible } = useTooltip(
    'Swap any tokens on BabySwap to get BABY as rewards for every trade that goes through Baby LP. You can claim the rewards at any time.',
    {
      placement: 'bottom',
    }
  )
  const HandleShowTrending = (v: boolean) => {
    setIsShowing(v)
    console.info(v)
    setIsMbShowing(v)
  }
  const handleConfirmTokenWarning = () => {
    const newTokenList = window.localStorage.getItem('redux_localstorage_simple_user')
    console.info(newTokenList)
    if (trendTokenA) {
      onCurrencySelection(Field.OUTPUT, trendTokenA)
      setTrendToken([])
    }
    setDismissTokenWarning(true)
  }

  const handSelectToken = (v: any) => {
    if (isMobile) {
      setIsShowing(false)
    }
    if (v) {
      const tokenList = Object.keys(allTokens[chainId ?? 56])
      const address = tokenList.filter((s) => s.toLocaleLowerCase() === v.address.toLocaleLowerCase())
      if (allTokens[56][address[0]]) {
        handleOutputSelect(allTokens[56][address[0]])
      } else {
        addToken(v)
        setTrendTokenA(v)
        setTrendToken([v])
        setDismissTokenWarning(false)
      }
    }
  }
  const isShowWarning = useMemo(() => {
    if (trendToken && trendToken.length > 0) {
      return true
    }
    return false
  }, [trendToken])

  const FinnalTokens = useMemo(() => {
    return trendToken && trendToken.length ? trendToken : urlLoadedTokens
  }, [urlLoadedTokens, trendToken])
  useEffect(() => {
    if (isShowing) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isShowing])
  return (
    <>
      <TokenWarningModal
        isOpen={FinnalTokens.length > 0 || isShowWarning}
        tokens={FinnalTokens}
        onConfirm={handleConfirmTokenWarning}
      />
      <SyrupWarningModal
        isOpen={isSyrup}
        transactionType={syrupTransactionType}
        onConfirm={handleConfirmSyrupWarning}
      />
      <CardNav />
      <StyleSmartRouter>
        <Text color="textSubtle" fontSize="12px">
          You can trade over <span style={{ color: '#164989', fontSize: '14px' }}>90%</span> of BEP 20 Tokens on
          BabySwap with better slippages, and Smart Router will choose the{' '}
          <span style={{ color: '#164989', fontSize: '14px' }}>best route</span> for you.
        </Text>
      </StyleSmartRouter>

      {isSwapMining && (
        <StyledTrade>
          {tooltipVisible && tooltip}
          <div style={{ width: '230px' }}>
            <Text color="#FFFFFF" style={{ display: 'flex', alignItems: 'center' }}>
              {t('Trade Mining Reward:')}
              <span ref={targetRef}>
                <StyleTips src="/images/tips.svg" alt="" />
              </span>
            </Text>
          </div>
          <Text color="#FFFFFF">{reward || '0.0000'} BABY</Text>
          <Button style={{ position: 'absolute', right: '20px', top: '20px' }} scale="sm" onClick={handleWithdrawTrade}>
            {t('Withdraw')}
          </Button>
        </StyledTrade>
      )}
      {/* <Link mb="12px" target='_blank' fontWeight={400} href="https://docs.babyswap.finance/products/exchange/smart-router">{t("What's a Smart Router A/B Test?")}</Link> */}
      {/* <Link mb="12px" target='_blank' fontWeight={400} href="https://exchange.babyswap.io/#/swap">
        {t("Try Smart Router for a Better Slippage →")}
      </Link> */}
      <Flex>
        {isShowing && !isMobile && <Trending SelectToken={handSelectToken} />}
        {isMobile && (
          <ActionSheet
            isOpen={isShowing}
            onClose={(event) => {
              event.preventDefault()
              setIsShowing(false)
            }}
          >
            <Box style={{ textAlign: 'center' }}>
              <IconButton
                onClick={() => {
                  setIsShowing(false)
                }}
                variant="text"
              >
                <ChevronDownIcon />
              </IconButton>
              <Trending SelectToken={handSelectToken} />
            </Box>
          </ActionSheet>
        )}
        <div style={{position:'relative'}}>
          <AppBody>
            <Wrapper id="swap-page">
              <ConfirmSwapModal
                isOpen={showConfirm}
                trade={trade}
                originalTrade={tradeToConfirm}
                onAcceptChanges={handleAcceptChanges}
                attemptingTxn={attemptingTxn}
                txHash={txHash}
                recipient={recipient}
                allowedSlippage={allowedSlippage}
                onConfirm={handleSwap}
                swapErrorMessage={swapErrorMessage}
                onDismiss={handleConfirmDismiss}
                amounts={amounts}
                factories={factories}
                pairs={pairs}
                paths={paths}
              />
              <PageHeader
                showTrend={HandleShowTrending}
                title={t('Exchange')}
                hasShow={isShowing}
                description={t('Trade tokens in an instant')}
              />
              <CardBody style={{ padding: '24px 12px' }}>
                <AutoColumn gap="md">
                  <CurrencyInputPanel
                    label={independentField === Field.OUTPUT && !showWrap && trade ? t('From') : t('From')}
                    value={formattedAmounts[Field.INPUT]}
                    usdtInput={usdtInputVal}
                    showMaxButton={!atMaxAmountInput}
                    currency={currencies[Field.INPUT]}
                    onUserInput={handleTypeInput}
                    onUsdtInput={handleUsdtInput}
                    onMax={handleMaxInput}
                    onSlideChange={HandleSlideInput}
                    onCurrencySelect={handleInputSelect}
                    otherCurrency={currencies[Field.OUTPUT]}
                    id="swap-currency-input"
                    showCommonBases
                    slidePercent={inputVal}
                    showMaxSlide
                    enterPage="swap_page"
                    expandTokens={expandTokens}
                  />
                  <AutoColumn justify="space-between">
                    <AutoRow justify={isExpertMode ? 'space-between' : 'center'} style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable>
                        <IconButton
                          variant="tertiary"
                          onClick={() => {
                            setApprovalSubmitted(false) // reset 2 step UI for approvals
                            onSwitchTokens()
                            handleCurrencyExchange()
                          }}
                          style={{ borderRadius: '50%' }}
                          // scale="sm"
                        >
                          <SwapIcon width="24px" />
                        </IconButton>
                      </ArrowWrapper>
                      {recipient === null && !showWrap && isExpertMode ? (
                        <LinkStyledButton id="add-recipient-button" onClick={() => onChangeRecipient('')}>
                          + Add a send (optional)
                        </LinkStyledButton>
                      ) : null}
                    </AutoRow>
                  </AutoColumn>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.OUTPUT]}
                    onUserInput={handleTypeOutput}
                    label={t('To')}
                    isEstimate={independentField === Field.INPUT && !showWrap}
                    showMaxButton={false}
                    currency={currencies[Field.OUTPUT]}
                    onCurrencySelect={handleOutputSelect}
                    otherCurrency={currencies[Field.INPUT]}
                    id="swap-currency-output"
                    enterPage="swap_page"
                    expandTokens={expandTokens}
                  />

                  {recipient !== null && !showWrap ? (
                    <>
                      <AutoRow justify="space-between" style={{ padding: '0 1rem' }}>
                        <ArrowWrapper clickable={false}>
                          <ArrowDown size="16" color={theme.colors.textSubtle} />
                        </ArrowWrapper>
                        <LinkStyledButton id="remove-recipient-button" onClick={() => onChangeRecipient(null)}>
                          - Remove send
                        </LinkStyledButton>
                      </AutoRow>
                      <AddressInputPanel id="recipient" value={recipient} onChange={onChangeRecipient} />
                    </>
                  ) : null}

                  {showWrap ? null : (
                    <Card padding=".25rem .75rem 0 .75rem" borderRadius="20px">
                      <AutoColumn gap="4px">
                        {Boolean(trade) && (
                          <RowBetween align="center">
                            <Text fontSize="14px">{t('Price')}</Text>
                            <TradePrice
                              price={trade?.executionPrice}
                              showInverted={showInverted}
                              setShowInverted={setShowInverted}
                            />
                          </RowBetween>
                        )}
                        {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
                          <RowBetween align="center">
                            <Text fontSize="14px">{t('Slippage Tolerance')}</Text>
                            <Text fontSize="14px">{allowedSlippage / 100}%</Text>
                          </RowBetween>
                        )}
                      </AutoColumn>
                    </Card>
                  )}
                </AutoColumn>
                <BottomGrouping>
                  {!account ? (
                    <ConnectWalletButton width="100%" />
                  ) : showWrap ? (
                    <Button disabled={Boolean(wrapInputError)} onClick={onWrap} width="100%">
                      {wrapInputError ??
                        (wrapType === WrapType.WRAP ? 'Wrap' : wrapType === WrapType.UNWRAP ? 'Unwrap' : null)}
                    </Button>
                  ) : noRoute && userHasSpecifiedInputOutput ? (
                    <GreyCard style={{ textAlign: 'center' }}>
                      <Text mb="4px">{t('Insufficient liquidity for this trade.')}</Text>
                    </GreyCard>
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <Button
                        onClick={approveCallback}
                        disabled={approval !== ApprovalState.NOT_APPROVED || approvalSubmitted}
                        style={{ width: '48%' }}
                        variant={approval === ApprovalState.APPROVED ? 'success' : 'primary'}
                      >
                        {approval === ApprovalState.PENDING ? (
                          <AutoRow gap="6px" justify="center">
                            Approving <Loader stroke="white" />
                          </AutoRow>
                        ) : approvalSubmitted && approval === ApprovalState.APPROVED ? (
                          'Approved'
                        ) : (
                          `Approve ${currencies[Field.INPUT]?.symbol}`
                        )}
                      </Button>
                      <Button
                        onClick={() => {
                          if (isExpertMode) {
                            handleSwap()
                          } else {
                            setSwapState({
                              tradeToConfirm: trade,
                              attemptingTxn: false,
                              swapErrorMessage: undefined,
                              showConfirm: true,
                              txHash: undefined,
                            })
                          }
                        }}
                        style={{ width: '48%' }}
                        id="swap-button"
                        disabled={
                          !isValid || approval !== ApprovalState.APPROVED || (priceImpactSeverity > 3 && !isExpertMode)
                        }
                        variant={isValid && priceImpactSeverity > 2 ? 'danger' : 'primary'}
                      >
                        {priceImpactSeverity > 3 && !isExpertMode
                          ? `${t('Price Impact High')}`
                          : `${t('Swap')}${priceImpactSeverity > 2 ? ' Anyway' : ''}`}
                      </Button>
                    </RowBetween>
                  ) : (
                    <Button
                      onClick={() => {
                        if (isExpertMode) {
                          handleSwap()
                        } else {
                          setSwapState({
                            tradeToConfirm: trade,
                            attemptingTxn: false,
                            swapErrorMessage: undefined,
                            showConfirm: true,
                            txHash: undefined,
                          })
                        }
                      }}
                      id="swap-button"
                      disabled={!isValid || (priceImpactSeverity > 3 && !isExpertMode) || !!swapCallbackError}
                      variant={isValid && priceImpactSeverity > 2 && !swapCallbackError ? 'danger' : 'primary'}
                      width="100%"
                    >
                      {swapInputError ||
                        (priceImpactSeverity > 3 && !isExpertMode
                          ? `Price Impact Too High`
                          : `${t('Swap')}${priceImpactSeverity > 2 ? ' Anyway' : ''}`)}
                    </Button>
                  )}
                  {showApproveFlow && <ProgressSteps steps={[approval === ApprovalState.APPROVED]} />}
                  {isExpertMode && swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
                </BottomGrouping>
              </CardBody>
            </Wrapper>
          </AppBody>
          {!trade && (
            <BridgeButton href="https://bridge.babyswap.finance">
              <img src="images/bscLogo.svg" alt="" />
              <Text color="white" fontSize={isMobile ? '13px' : '14px'}>
                Deposit your assets to BNB Chain via the Bridge
              </Text>
              <img src="images/Fill_4.svg" alt="" />
            </BridgeButton>
          )}
                <AdvancedSwapDetailsDropdown trade={trade} factories={factories} amounts={amounts} pairs={pairs} paths={paths} />
                <SwapImage style={{width:themes.isDark?'200px':'170px'}} src={src} alt="Swap" />
        </div>
      </Flex>
            
    </>
  )
}

export default Swap
