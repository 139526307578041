import React, { useContext } from 'react'
import { AlertCircle, CheckCircle } from 'react-feather'
import { Text, Flex, Button, TwitterIcon, TelegramIcon } from '@metaswap/uikit'
import styled, { ThemeContext } from 'styled-components'
import { useActiveWeb3React } from '../../hooks'
import { getBscScanLink } from '../../utils'
import { ExternalLink } from '../Shared'
import { AutoColumn } from '../Column'
import { AutoRow } from '../Row'
import TranstionSussIcon from './images/TranstionSussIcon'

const RowNoFlex = styled(AutoRow)`
  flex-wrap: nowrap;
  align-items:initial ;
`

export default function TransactionPopup({
  hash,
  success,
  summary,
}: {
  hash: string
  success?: boolean
  summary?: string
}) {
  const { chainId } = useActiveWeb3React()

  const theme = useContext(ThemeContext)

  const toShareLink = (type) => {
    const link = document.createElement('a')
    if (type === 'twitter') {
      link.href = `https://twitter.com/intent/tweet?text= 💰 Swapped ${summary} on @babyswap_bsc! %0Ahttps://bscscan.com/tx/${hash}  %0A%0A🥳 Enjoy the best trading route chosen by Smart Router with better slippages %0A🤩 Trade almost all BEP 20 tokens here  %0A%0A ➡️ Start trading now: exchange.babyswap.finance %0A%0A%23BabySwap`
    } else {
      link.href = `https://telegram.me/share/url?url=https://exchange.babyswap.finance&text=I swapped ${summary} on BabySwap just now!%0A%0Ahttps://bscscan.com/tx/${hash}
      %0A%0ACome and trade over 90% of BEP 20 tokens with me and enjoy the amazing trading experience provided by Smart Router with better slippages!
      %0A%0A➡️ Start trading now: exchange.babyswap.finance`
    }

    link.target = '_blank'
    link.click()
  }

  return (
    <RowNoFlex>
      <div style={{ paddingRight: 16 }}>
        {success ? (
          <TranstionSussIcon />
        ) : (
          <AlertCircle color={theme.colors.failure} size={24} />
        )}
      </div>
      <div>
        <AutoColumn gap="8px">
          <Text fontSize='18px' fontWeight={800}>{summary ?? `Hash: ${hash.slice(0, 8)}...${hash.slice(58, 65)}`}</Text>
          {chainId && <ExternalLink style={{marginBottom:'20px'}} href={getBscScanLink(chainId, hash, 'transaction')}>View on bscscan</ExternalLink>}
        </AutoColumn>
        <Text mb="20px" fontSize='14px'>Share your swap on Web 2 social media and meet our fellow babies!</Text>
        <Flex justifyContent="flex-start">
          <Button scale='sm' mr={10} onClick={() => { toShareLink('twitter') }}  style={{ fontSize: '12px',backgroundColor:"#4E93EA"}} startIcon={<TwitterIcon color='#fff' />}>Twitter</Button>
          <Button scale='sm' onClick={() => { toShareLink('tg') }}  style={{ fontSize: '12px',backgroundColor:"#4E93EA" }} startIcon={<TelegramIcon color='#fff' />}>Telegram</Button>
        </Flex>
      </div>

    </RowNoFlex>
  )
}
