/* eslint-disable vars-on-top */
import { parseBytes32String } from '@ethersproject/strings'
import { Currency, ETHER, Token, currencyEquals } from '@metaswap/sdk'
import { useMemo } from 'react'
import { useSelectedTokenList, WrappedTokenInfo, useTokenList } from '../state/lists/hooks'
import { NEVER_RELOAD, useSingleCallResult } from '../state/multicall/hooks'
import { EXPAND_LIST_OF_LISTS } from '../constants/lists'
// eslint-disable-next-line import/no-cycle
import { useUserAddedTokens } from '../state/user/hooks'
import { isAddress } from '../utils'

import { useActiveWeb3React } from './index'
import { useBytes32TokenContract, useTokenContract } from './useContract'

export function useAllTokens(): { [address: string]: Token } {
  const { chainId } = useActiveWeb3React()
  const userAddedTokens = useUserAddedTokens()
  const allTokens = useSelectedTokenList()
  const otherTokens = useExpendTokens()

  return useMemo(() => {
    if (!chainId) return {}
    return (
      userAddedTokens
        // reduce into all ALL_TOKENS filtered by the current chain
        .reduce<{ [address: string]: Token }>(
          (tokenMap, token) => {
            if (otherTokens[token.address]) {
              tokenMap[token.address] = otherTokens[token.address]
              // tokenMap[token.address] = new WrappedTokenInfo(otherTokens[token.address], [])
            } else {
              tokenMap[token.address] = token
            }
            return tokenMap
          },
          // must make a copy because reduce modifies the map, and we do not
          // want to make a copy in every iteration
          { ...allTokens[chainId] }
        )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, userAddedTokens, allTokens])
}

export function useExpendTokens(): { [address: string]: WrappedTokenInfo } {
  const { chainId } = useActiveWeb3React()
  const allCGTokens = useTokenList(EXPAND_LIST_OF_LISTS[0]);
  const allCMCTokens = useTokenList(EXPAND_LIST_OF_LISTS[1]);

  return useMemo(() => {
    if (!chainId) return {}
    return { ...allCMCTokens[chainId], ...allCGTokens[chainId] }
  }, [chainId, allCGTokens, allCMCTokens])
}

// Check if currency is included in custom list from user storage
export function useIsUserAddedToken(currency: Currency): boolean {
  const userAddedTokens = useUserAddedTokens()
  return !!userAddedTokens.find((token) => currencyEquals(currency, token))
}

// parse a name or symbol from a token response
const BYTES32_REGEX = /^0x[a-fA-F0-9]{64}$/
function parseStringOrBytes32(str: string | undefined, bytes32: string | undefined, defaultValue: string): string {
  return str && str.length > 0
    ? str
    : bytes32 && BYTES32_REGEX.test(bytes32)
      ? parseBytes32String(bytes32)
      : defaultValue
}

// undefined if invalid or does not exist
// null if loading
// otherwise returns the token
export function useToken(tokenAddress?: string): Token | undefined | null {
  const { chainId } = useActiveWeb3React()
  let allTokens = {}
  const tokens = useAllTokens()
  // const expandTokens = useExpendTokens()
  // const userAddedTokens = useUserAddedTokens()
  // let cgTokens;
  // let cmcTokens;

  // if (localStorage.getItem('cgList')) {
  //   const cgList = JSON.parse(localStorage.getItem('cgList') || '').tokens
  //   const filterCgTokens = cgList.filter((token) => token.address === tokenAddress || token.symbol.startsWith(tokenAddress))
  //   if(filterCgTokens.length>0){
  //     cgTokens = {
  //       [filterCgTokens[0].address]:new WrappedTokenInfo(filterCgTokens[0],[],'CoinGecko'),
  //     }
  //   }
  // }

  // if (localStorage.getItem('cmcList')) {
  //   const cmcList = JSON.parse(localStorage.getItem('cmcList') || '').tokens
  //   const filterCmcTokens = cmcList.filter((token) => token.address === tokenAddress || token.symbol.startsWith(tokenAddress))
  //   if (filterCmcTokens.length > 0) {
  //     cmcTokens = {
  //       [filterCmcTokens[0].address]: new WrappedTokenInfo(filterCmcTokens[0], [], 'CoinMarketCap'),
  //     }
  //   }
  // }


  // const USER_ADD_TOKEN = JSON.parse(localStorage.getItem('redux_localstorage_simple_user') ?? '{"tokens": {"56": {}, "97": {}}}')

  allTokens = { ...allTokens ,...tokens }

  const address = isAddress(tokenAddress)

  const tokenContract = useTokenContract(address || undefined, false)
  const tokenContractBytes32 = useBytes32TokenContract(address || undefined, false)

  const token: Token | undefined = address ? allTokens[address] : undefined
  // const expandToken: Token | undefined = address ? expandTokens[address] : undefined

  const tokenName = useSingleCallResult(token ? undefined : tokenContract, 'name', undefined, NEVER_RELOAD)
  const tokenNameBytes32 = useSingleCallResult(
    token ? undefined : tokenContractBytes32,
    'name',
    undefined,
    NEVER_RELOAD
  )
  const symbol = useSingleCallResult(token ? undefined : tokenContract, 'symbol', undefined, NEVER_RELOAD)
  const symbolBytes32 = useSingleCallResult(token ? undefined : tokenContractBytes32, 'symbol', undefined, NEVER_RELOAD)
  const decimals = useSingleCallResult(token ? undefined : tokenContract, 'decimals', undefined, NEVER_RELOAD)

  return useMemo(() => {
    // if(userAddedTokens.some(s => s.address.toLocaleLowerCase() === token?.address.toLocaleLowerCase())) return undefined
    if (token) return token
    // if (expandToken) return undefined
    if (!chainId || !address) return undefined
    if (decimals.loading || symbol.loading || tokenName.loading) return null
    if (decimals.result) {
      return new Token(
        chainId,
        address,
        decimals.result[0],
        parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
        parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token')
      )
    }
    return undefined
  }, [
    // userAddedTokens,
    address,
    chainId,
    decimals.loading,
    decimals.result,
    symbol.loading,
    symbol.result,
    symbolBytes32.result,
    token,
    // expandToken,
    tokenName.loading,
    tokenName.result,
    tokenNameBytes32.result,
  ])
}

export function useCurrency(currencyId: string | undefined): Currency | null | undefined {
  const isBNB = currencyId?.toUpperCase() === 'BNB'
  const token = useToken(isBNB ? undefined : currencyId)
  return isBNB ? ETHER : token
}
