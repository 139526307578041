import React from 'react'
import styled from 'styled-components'
import {Flex,Text} from '@metaswap/uikit'
import { escapeRegExp } from '../../utils'

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string,isWidth?:string|number }>`
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.text)};
  width: 0;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  width: ${({ isWidth }) => isWidth?'160px':'55px'};
  background:${({ theme }) => theme.isDark? theme.colors.tertiary:'rgb(218, 236, 251)'};;
  font-size: 16px;
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 10px ;
  border-radius: 23px;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: ${({ theme }) => theme.colors.textSubtle};
  }
  &:focus-visible{
    width: 160px;
    transition: width 0.5s ease 0s
  }
  
`
const StyledFlex = styled(Flex)`
   background:${({ theme }) => theme.isDark? theme.colors.tertiary:'rgb(218, 236, 251)'};
  border-radius: 23px;
  padding-left: 10px;
`

const inputRegex = RegExp(`^\\d*(?:\\\\[.])?\\d*$`) // match escaped "." characters via in a non-capturing group

export const Input = React.memo(function InnerInput({
  value,
  onUsdtInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUsdtInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const enforcer = (nextUserInput: string) => {
    if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
      onUsdtInput(nextUserInput)
    }
  }

  return (
   <StyledFlex alignItems="center">
    {value&& <Text>$</Text>}
     <StyledInput
      {...rest}
      isWidth={value}
      value={value}
      onChange={(event) => {
        console.info(event.target.value,'event.target.value')
        // replace commas with periods, because uniswap exclusively uses period as the decimal separator
        enforcer(event.target.value.replace(/,/g, '.'))
      }}
      // universal input options
      inputMode="decimal"
      title="Token Amount"
      autoComplete="off"
      autoCorrect="off"
      // text-specific options
      type="text"
      // pattern="^[0-9]*[.,]?[0-9]*$"
      placeholder={placeholder || '0.0'}
      minLength={1}
      maxLength={79}
      spellCheck="false"
    />
   </StyledFlex>
  )
})

export default Input
