import React, { useEffect, useRef } from 'react'
import { useCountUp } from 'react-countup'
import { Text, Skeleton } from '@metaswap/uikit'
import styled from 'styled-components'

const LineTextStyle = styled(Text)`
  white-space: nowrap;
`
export interface CardValueProps {
  value: number
  decimals?: number
  fontSize?: string
  lineHeight?: string
  prefix?: string
  unit?: string
  bold?: boolean
  color?: string
  fontWeight?: string | number
}

const NumberValue: React.FC<CardValueProps> = (props) => {
  const countUpRef = React.useRef(null)
  const {
    value,
    decimals,
    fontSize = '16px',
    fontWeight = '500',
    prefix = '',
    unit = '',
    bold = true,
    color = 'text',
  } = props
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: value,
    duration: 1,
    separator: ',',
    decimals:
      // eslint-disable-next-line no-nested-ternary
      decimals !== undefined ? decimals : value < 0 ? 4 : value > 1e5 ? 0 : 3,
  })

  const updateValue = useRef(update)

  useEffect(() => {
    updateValue.current(value)
  }, [value, updateValue])

  return (
    <>
      {value >= 0 ? (
        <LineTextStyle bold={bold} fontSize={fontSize} color={color} fontWeight={fontWeight} fontFamily="Kanit">
          {prefix}
          <span ref={countUpRef} />
          {unit}
        </LineTextStyle>
      ) : (
        <Skeleton height={20} width={60} />
      )}
    </>
  )
}

export default NumberValue
