import { createReducer } from '@reduxjs/toolkit'
// eslint-disable-next-line import/no-unresolved
import { TokenList } from '@uniswap/token-lists/dist/types'
import {cgList, cmcList} from './actions'

export interface OtherListsState {
  readonly cmcListUrl: TokenList | null
  readonly cgListUrl: TokenList | null
}

const initialState: OtherListsState = {
  cmcListUrl: null,
  cgListUrl: null
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(cmcList, (state, action) => {
      state.cmcListUrl = action.payload
    })
    .addCase(cgList, (state, action) => {
      state.cgListUrl = action.payload
    })
)
