import { useCallback, useEffect, useState } from 'react'
import { AbiItem } from 'web3-utils'
import { getWeb3NoAccount } from 'utils/web3'
import BigNumber from 'bignumber.js'
import { useBlockNumber } from 'state/application/hooks'
import { CALC_PRICE_ADDRESS, BABY_ADDRESS } from '../constants'
import CalcPriceABI from '../constants/abis/CalcPrice.json'

export default function useBabyPrice () {
    const [price, setPrice] = useState(0)
    const web3 = getWeb3NoAccount()
    const latestBlockNumber = useBlockNumber()
    const fetchPrice = useCallback(async () => {
        const calcContract = new web3.eth.Contract(CalcPriceABI as unknown as AbiItem, CALC_PRICE_ADDRESS)
        const tokenPricesResult = await calcContract.methods.getDefaultTokenPrices([BABY_ADDRESS]).call()
        const babyPrice = new BigNumber(tokenPricesResult[0]).div(10**18).toNumber()
        setPrice(babyPrice)
    }, [web3])
    useEffect(() => {
        fetchPrice()
    }, [latestBlockNumber, fetchPrice])

    return price
}
