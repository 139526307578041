/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useState } from 'react'
import { Token, currencyEquals } from '@metaswap/sdk'
import { Button, Flex, Box, Text, ButtonMenu, ButtonMenuItem,useMatchBreakpoints } from '@metaswap/uikit'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getTokenTrending } from 'utils/api'
import { useTranslation } from 'contexts/Localization'
import useBabyPrice from 'hooks/useBabyPrice'
import { defaultTrendingTokenSrc, decmiclNum } from 'utils/trendingCom'
import NumberValue from './NumberValue'

const StyleContainer = styled(Flex)`
  justify-content: space-between;
  flex-direction: column;
  ${({ theme }) => theme.mediaQueries.sm} {
    flex-direction: row;
  }
`

const StyleGainers = styled(Box)`
  margin-bottom: 20px;
  width: 100%;
  height: 100%;
 
 
  box-sizing: border-box;
  border-radius: 30px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  // margin-right:80px;
`

const StyleTitle = styled(Flex)`
  justify-content: center;
  color: #e89d39;
  font-family: 'Baloo Bhai';
  font-size: 32px;
  line-height: 51px;
`

const StyleHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  // justify-items:center;
  margin-bottom: 10px;
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

const StyleHeaderItem = styled.div`
  display: grid;
`

const StyleToken = styled.div`
  display: grid;
  grid-gap: 20px;
  padding: 10px 0;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  ${({ theme }) => theme.mediaQueries.sm} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  &:hover {
    cursor: pointer;
    background-color: #f5fbff;
  }
`

const StyleTokenItem = styled.div`
  display: grid;
`
const TokenImg = styled.img`
  width: 26px;
  height: 26px;
  /* background: #fff1d9; */
  border-radius: 50%;
`
const StyleBtn = styled(Flex)`
  height: 30px;
  padding: 10px;
  border: 1px solid #fe993b;
  border-radius: 60px;
  justify-content: center;
  font-family: 'Baloo Bhai';
  font-size: 10px;
  text-align: center;
  color: #e89d39;
`

const StyleBorder = styled.div`
  /* margin-top: 20px; */
  width: 100%;
  height: 1px;
  background: #f5fbff;
`
const Container = styled(Box)`
  
 
  padding: 20px;
  margin-right:30px ;
   width: 100%;
  ${({ theme }) => theme.mediaQueries.lg} {
    width: 100%;
    box-shadow:-3px 3px 11px rgba(130,255,227,0.29), 3px -3px 13px rgba(73,102,255,0.22) ;
    border: 1px solid ${({ theme }) => (theme.isDark ? '#E89D39' : '#93CFFA')};
  border-radius:32px ;
  }
`

const FlexDeepLink = styled(Flex)`
  cursor: pointer;
`

const TableTitleText = styled.div`
  color: #8d9fb5;
`

const StylePageHeader = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  img {
    ${({ theme }) => theme.mediaQueries.xs} {
      border-radius: 0px;
      margin-top: 0px;
    }
    ${({ theme }) => theme.mediaQueries.sm} {
      border-radius: 30px;
      margin-top: 20px;
    }
  }
`

// const defaultTrendingTokenSrc = 'https://baby-upload.s3.amazonaws.com/images/coins/placeholder.png';

const Trending:React.FC<{SelectToken:(token:any)=>void}> = ({SelectToken}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const [gainersData, setGainersData] = useState([])
  const [tradingVolume, setTradingVolume] = useState([])
  const [activeIndex, setActiveIndex] = useState(0)
  const [gainersPromotedNum, setGainersPromotedNum] = useState(0)
  const [tradingVolumePromotedNum, setTradingVolumePromotedNum] = useState(0)
  const babyPrice = useBabyPrice()
  const {isMobile} = useMatchBreakpoints()
  const stakingTokenPrice = Number(babyPrice)

  const fetchGainersData = async () => {
    getTokenTrending({ trendType: 1, pageSize: 10 }).then((res) => {
      const arr = res.data.data
      setGainersData(arr)
      let num = 0
      ;(res.data.data || []).forEach((item) => {
        if (item.promoted) {
          num++
        }
      })
      setGainersPromotedNum(num)
    })
  }

  const fetchTradingVolumeData = async () => {
    getTokenTrending({ trendType: 2, pageSize: 10 }).then((res) => {
      setTradingVolume(res.data.data)
      let num = []
      num = (res.data.data || []).filter((item) => {
        return item.promoted
      })
      setTradingVolumePromotedNum(num.length)
    })
  }

  useEffect(() => {
    fetchGainersData()
    fetchTradingVolumeData()
    setInterval(() => {
      fetchGainersData()
      fetchTradingVolumeData()
    }, 3600000)
  }, [])

  const showBabyPrice = (token) => {
    if (token.tokenName === 'BABY') {
      return Number(stakingTokenPrice)
    }
    return token.tokenPrice
  }

  const handleClick = (index) => {
    setActiveIndex(index)
  }

  const trendingJumpToSwap = (token:any) => {
  //  const swapUrl =  `${BASE_EXCHANGE_URL}/#/swap?outputCurrency=${token.contractAddress}`
const chainId = 56;

 const newToken = new Token(chainId,token.contractAddress,token.decimal,token.tokenSymbol,token.tokenName)
  
    SelectToken(newToken)
  }

  // chainId,
  // address,
  // decimals.result[0],
  // parseStringOrBytes32(symbol.result?.[0], symbolBytes32.result?.[0], 'UNKNOWN'),
  // parseStringOrBytes32(tokenName.result?.[0], tokenNameBytes32.result?.[0], 'Unknown Token')

  return (
    <Container maxWidth="600px">
      <Box mb="20px">
        <ButtonMenu  activeIndex={activeIndex} onItemClick={handleClick} scale="sm" variant="buttonMenu">
          <ButtonMenuItem id="swap-nav-link">{t('Top Gainers')}</ButtonMenuItem>
          <ButtonMenuItem id="pool-nav-link">{t('Top Trading Volume (24h)')}</ButtonMenuItem>
        </ButtonMenu>
      </Box>
      <StyleContainer>
        {activeIndex === 0 && (
          <StyleGainers>
            <StyleHeader>
              <StyleHeaderItem style={{ justifyItems: 'start' }}>
                <TableTitleText>Token</TableTitleText>
              </StyleHeaderItem>
              <StyleHeaderItem style={{ justifyItems: 'left' }}>
                <TableTitleText>Price</TableTitleText>
              </StyleHeaderItem>
              <StyleHeaderItem style={{ justifyItems: 'end' }}>
                <TableTitleText>Increase</TableTitleText>
              </StyleHeaderItem>
              {!isMobile&&<StyleHeaderItem style={{ justifyItems: 'end' }}>
                <TableTitleText>Action</TableTitleText>
              </StyleHeaderItem>}
            </StyleHeader>
            {gainersData.map((token: any, index) => (
              <>
                <StyleToken key={token.id} onClick={() => trendingJumpToSwap(token)}>
                  <StyleTokenItem style={{ justifyItems: 'start' }}>
                    <FlexDeepLink alignItems="center">
                      <TokenImg
                        src={
                          token.tokenIcon ||
                          `https://baby-upload.s3.amazonaws.com/images/coins/${token.contractAddress?.toLocaleLowerCase()}.png`
                        }
                        // eslint-disable-next-line no-param-reassign
                        onError={(e) => {
                          e.currentTarget.src = defaultTrendingTokenSrc
                        }}
                      />
                      <Text fontSize="14px" ml="5px" mr="5px">
                        {token.tokenName}
                      </Text>

                      {/* <img
                      style={{ cursor: 'pointer' }}
                      onClick={handleToSwap}
                      onKeyDown={handleToSwap}
                      src="/images/trending.svg"
                      alt="312"
                    /> */}
                    </FlexDeepLink>
                  </StyleTokenItem>
                  <StyleTokenItem style={{ justifyItems: 'start' }}>
                    <Flex alignItems="center" justifyContent="center">
                      <NumberValue
                        value={Number(showBabyPrice(token)) || 0}
                        prefix="$"
                        decimals={decmiclNum(showBabyPrice(token))}
                        bold={false}
                      />
                    </Flex>
                  </StyleTokenItem>
                  <StyleTokenItem style={{ justifyItems: 'end' }}>
                    {index >= gainersPromotedNum ? (
                      <NumberValue value={Number(token.trendNum) || 0} unit="%" decimals={2} bold={false} />
                    ) : (
                      <StyleBtn>{t('Promoted')}</StyleBtn>
                    )}
                  </StyleTokenItem>
                  {!isMobile&&<StyleTokenItem style={{ justifyItems: 'end' }}>
                    <FlexDeepLink alignItems="center">
                      
                    <Button size="sm" scale='sm'>
                        Trade
                      </Button>

                      {/* <img
                      style={{ cursor: 'pointer' }}
                      onClick={handleToSwap}
                      onKeyDown={handleToSwap}
                      src="/images/trending.svg"
                      alt="312"
                    /> */}
                    </FlexDeepLink>
                  </StyleTokenItem>}
                </StyleToken>
                {gainersPromotedNum !== 0 && index === gainersPromotedNum - 1 && <StyleBorder />}
              </>
            ))}
          </StyleGainers>
        )}

        {activeIndex === 1 && (
          <StyleGainers>
            <StyleHeader>
              <StyleHeaderItem style={{ justifyItems: 'start' }}>
                <TableTitleText>Token</TableTitleText>
              </StyleHeaderItem>
              <StyleHeaderItem style={{ justifyItems: 'left' }}>
                <TableTitleText>Price</TableTitleText>
              </StyleHeaderItem>
              <StyleHeaderItem style={{ justifyItems: 'center' }}>
                <TableTitleText>24h Volume</TableTitleText>
              </StyleHeaderItem>
              {!isMobile&&<StyleHeaderItem style={{ justifyItems: 'end' }}>
                <TableTitleText>Action</TableTitleText>
              </StyleHeaderItem>}
            </StyleHeader>
            {tradingVolume.map((token: any, index) => (
              <>
                {token.tokenICon}
                <StyleToken key={token} onClick={() => trendingJumpToSwap(token)}>
                  <StyleTokenItem style={{ justifyItems: 'start' }}>
                    <FlexDeepLink alignItems="center">
                      <TokenImg
                        src={
                          token.tokenIcon ||
                          `https://baby-upload.s3.amazonaws.com/images/coins/${token.contractAddress?.toLocaleLowerCase()}.png`
                        }
                        // eslint-disable-next-line no-param-reassign
                        onError={(e) => {
                          e.currentTarget.src = defaultTrendingTokenSrc
                        }}
                      />
                      <Text fontSize="14px" ml="5px" mr="5px">
                        {token.tokenName}
                      </Text>

                      {/* <img
                      style={{ cursor: 'pointer' }}
                      onClick={handleToSwap}
                      onKeyDown={handleToSwap}
                      src="/images/trending.svg"
                      alt="312"
                    /> */}
                    </FlexDeepLink>
                  </StyleTokenItem>
                  <StyleTokenItem style={{ justifyItems: 'start' }}>
                    <Flex alignItems="center" justifyContent="center">
                      <NumberValue
                        value={Number(showBabyPrice(token)) || 0}
                        prefix="$"
                        decimals={decmiclNum(showBabyPrice(token))}
                        bold={false}
                      />
                    </Flex>
                  </StyleTokenItem>
                  <StyleTokenItem style={{ justifyItems: 'center' }}>
                    {index >= tradingVolumePromotedNum ? (
                      <NumberValue
                        value={Number(+token.trendNum) / 1000000 || 0}
                        prefix="$"
                        unit="M"
                        decimals={2}
                        bold={false}
                      />
                    ) : (
                      <StyleBtn>{t('Promoted')}</StyleBtn>
                    )}
                  </StyleTokenItem>
                  {!isMobile&&<StyleTokenItem style={{ justifyItems: 'end' }}>
                    <FlexDeepLink alignItems="center">
                      
                    <Button size="sm" scale='sm'>
                        Trade
                      </Button>

                      {/* <img
                      style={{ cursor: 'pointer' }}
                      onClick={handleToSwap}
                      onKeyDown={handleToSwap}
                      src="/images/trending.svg"
                      alt="312"
                    /> */}
                    </FlexDeepLink>
                  </StyleTokenItem>}
                </StyleToken>
                {tradingVolumePromotedNum !== 0 && index === tradingVolumePromotedNum - 1 && <StyleBorder />}
              </>
            ))}
          </StyleGainers>
        )}
      </StyleContainer>
      <Flex justifyContent="center">
        <Text mt="20px" fontSize="14px" textAlign="center">
          * The data is supported by CoinMarketCap. Please do your own research before making investment decisions.
        </Text>
      </Flex>
    </Container>
  )
}

export default Trending
