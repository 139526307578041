import React from 'react'
import styled from 'styled-components'

interface ActionSheetProps {
  isOpen: boolean
  onClose: (event) => void
  children: React.ReactNode
  onSelect?: (option: string) => void
}

const ActionContainer = styled.div`
  position: fixed;
  top: 8%;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  overflow-y: scroll;
`
const ActionSheetBox = styled.div<{isOpen:boolean}>`
   width: 100%;
  background-color: #fff;
  /* border-radius: 8px; */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transform: ${({isOpen})=>isOpen?0:'100%'} ;
  transition: transform 0.2s ease;
`

const ActionSheet: React.FC<ActionSheetProps> = ({ isOpen, onClose, children }) => {
  return (
    <>
      {isOpen && (
        <ActionContainer >
          <ActionSheetBox isOpen>{children}</ActionSheetBox>
        </ActionContainer>
      )}
    </>
  )
}

export default ActionSheet
