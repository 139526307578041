import { LUCK_TRADE_TOKNE, BABY_FACTORY } from '../constants'

const HOST = process.env.REACT_APP_HOST ?? 'https://server.babyswap.io'

const getCheckedHashToServer = (argsArr: any, hash: string) => {
  let flagHash = false
  const threeArr = argsArr[2] || []
  const fourArr = argsArr[3] || []
  // https://server.babyswap.io
  if (LUCK_TRADE_TOKNE.map(d => d.toLocaleLowerCase()).some(s => threeArr.map(d => d.toLocaleLowerCase()).includes(s)) && BABY_FACTORY.some(s => fourArr.includes(s))) {
    flagHash = true
  } else {
    flagHash = false
  }

  const fetchUpdateHashToken = async () => {
    try {
      // api45  https://tlb.unicornboostercapital.com/doc.html#/default/api-controller/subSwapTransactionUsingGET
      await fetch(`${HOST}/api/subSwapTransaction?hash=${hash}`, {
        method: 'GET',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        // data: JSON.stringify({ token }), // POST
      })
    } catch (e) {
      console.error(e)
    }
  }

  if (flagHash) {
    fetchUpdateHashToken()
  }
}
export default getCheckedHashToServer
