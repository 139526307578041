import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'
import TradeMiningABI from 'constants/abis/trade-mining.json'
import muticall, { Call } from 'utils/muticall'
import { TRADE_MINING_ADDRESS, USDT_ADDRESS } from 'constants/index'
import { FACTORY_ADDRESS } from '@metaswap/sdk'
// import useBabyPrice from 'hooks/useBabyPrice'

const BIG_TEN = new BigNumber(10)

export const useTradeReward = (amounts: string[], pairs: string[], factories: string[], paths: string[]) => {
    const [reward, setReward] = useState('0')
    // const [rewardUsdt, setRewardUsd] = useState('0')
    // const babyPrice = useBabyPrice()
    const fetchTradingReward = useCallback(async () => {
        const babyPaths: {pair: string, amount: string, outputToken: string} [] = []
        if (factories.length) {
            factories.forEach((f, i) => {
                if (f.toLocaleLowerCase() === FACTORY_ADDRESS.toLocaleLowerCase()) {
                    babyPaths.push({
                        pair: pairs[i],
                        amount: amounts[i+1],
                        outputToken: paths[i+1]
                    })
                }
            })
            const len = babyPaths.length
            const calls: Call [] = babyPaths.map(d => ({
                address: TRADE_MINING_ADDRESS,
                name: 'pairOfPid',
                params: [d.pair]
            }))
            calls.push({
                address: TRADE_MINING_ADDRESS,
                name: 'targetToken',
            })
            const pids = await muticall(TradeMiningABI, calls)
            const targetToken = USDT_ADDRESS
            const poolCalls: Call [] = []
            babyPaths.forEach((d, i) => {
                poolCalls.push((
                    {
                        address: TRADE_MINING_ADDRESS,
                        name: 'poolInfo',
                        params: [pids[i].toString()]
                    }
                ))
            })
            babyPaths.forEach((d) => {
                poolCalls.push((
                    {
                        address: TRADE_MINING_ADDRESS,
                        name: 'getQuantity',
                        params: [d.outputToken, d.amount, targetToken]
                    }
                ))
            })
            const poolInfos = await muticall(TradeMiningABI, poolCalls)
            let totalReward = new BigNumber(0)
            babyPaths.forEach((d, i) => {
                const poolInfo = poolInfos[i]
                const userQuantity = poolInfos[i+len][0].toString()
                const onePathReward = new BigNumber(poolInfo.allocMdxAmount.toString()).times(userQuantity).div(poolInfo.quantity.toString())
                totalReward = totalReward.plus(onePathReward)
            })
            const totalRewardValue = new BigNumber(totalReward.toString()).div(BIG_TEN.pow(18)).toFixed(4)
            // const totalRewardUsdt = new BigNumber(totalReward.toString()).times(babyPrice).div(BIG_TEN.pow(18)).toFixed(4)
            setReward(totalRewardValue)
            // setRewardUsd(totalRewardUsdt)
        }
        
    }, [factories, amounts, pairs, paths])

    useEffect(() => {
        fetchTradingReward()
    }, [fetchTradingReward])

    return { reward, fetchTradingReward }
}

export default function useTradeMining (account) {
    const [reward, setReward] = useState('')
    const fetchReward = async () => {
        try {
            const callss = [
                {
                   address: TRADE_MINING_ADDRESS,
                   name: 'poolLength' 
                }
                // {
                //    address: TRADE_MINING_ADDRESS,
                //    name: 'startBlock' 
                // }
            ]
            const [poolLength] = await muticall(TradeMiningABI, callss)
            const pools = Number(poolLength.toString())
            let rewardCount = new BigNumber(0);
            if (pools > 0) {
                const calles = new Array(pools)
                for (let i = 0; i < pools; i++) {
                    calles[i] = {
                        address: TRADE_MINING_ADDRESS,
                        name: 'getUserReward',
                        params: [i, account]
                    }
                }
                const rewards = await muticall(TradeMiningABI, calles)
                rewards.forEach(d => {
                    rewardCount = rewardCount.plus(new BigNumber(d[0].toString()).div(new BigNumber(10).pow(18)))
                });
            }
            setReward(rewardCount.toFormat(4))
            return rewardCount
        } catch (error) {
            return undefined
        }
    }
    fetchReward()
    return { reward, fetchReward }
}
