
import React from 'react'
import { Svg, SvgProps } from '@metaswap/uikit'

const TranstionSussIcon: React.FC<SvgProps> = (props) => {
    return (
        <Svg width="44" height="44" viewBox="0 0 44 44" fill="none"   {...props}>
            <path d="M21.9993 40.3327C11.8741 40.3327 3.66602 32.1246 3.66602 21.9994C3.66602 11.8741 11.8741 3.66602 21.9993 3.66602C32.1246 3.66602 40.3327 11.8741 40.3327 21.9994C40.3216 32.12 32.12 40.3216 21.9993 40.3327ZM21.97 36.666H21.9993C30.0967 36.6579 36.6554 30.0893 36.6514 21.992C36.6473 13.8947 30.082 7.33269 21.9847 7.33269C13.8874 7.33269 7.32207 13.8947 7.31802 21.992C7.31397 30.0893 13.8727 36.6579 21.97 36.666ZM18.3327 31.166L10.9993 23.8327L13.5843 21.2477L18.3327 25.9777L30.4144 13.896L32.9993 16.4994L18.3327 31.166Z" fill="#58D6A9" />
        </Svg>
    )
}

export default TranstionSussIcon
