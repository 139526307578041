import React, { useState, useCallback, useEffect } from 'react'
import { Currency, Pair } from '@metaswap/sdk'
import { Button, ChevronDownIcon, Text, Slider, CopyIcon, Flex } from '@metaswap/uikit'
import styled from 'styled-components'
import { darken } from 'polished'
import { useTranslation } from 'contexts/Localization'
import { registerToken } from 'utils/wallet'
import useToast from 'hooks/useToast'
import copy from 'copy-to-clipboard'
// import { useDebounce } from 'hooks'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { WrappedTokenInfo } from '../../state/lists/hooks'
import { RowBetween } from '../Row'
import { Input as NumericalInput } from '../NumericalInput'
import { Input as UsdtInputPanel } from '../UsdtInputPanel'
import { useActiveWeb3React } from '../../hooks'

const IconBoxWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`
const IconBoxInnerWrap = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const IconBox = styled.div`
  display: inline-block;
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 6px;
`
const SlideContainer = styled.div`
  & input {
    cursor: pointer;
  }
`

const InputRow = styled.div<{ selected: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: ${({ selected }) => (selected ? '0.75rem 0.5rem 0.75rem 1rem' : '0.75rem 0.75rem 0.75rem 1rem')};
`
const CurrencySelect = styled.button<{ selected: boolean }>`
  align-items: center;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  background-color: transparent;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#FFFFFF')};
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0;
  :focus,
  :hover {
    background-color: ${({ theme }) => (theme.isDark ? '#1D1D28' : '#d5ecfd')};
  }
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0.75rem 1rem 0 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.colors.textSubtle)};
  }
`
const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const InputPanel = styled.div<{ hideInput?: boolean }>`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? '8px' : '20px')};
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
`
const Container = styled.div<{ hideInput: boolean }>`
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.input};
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
const StyledText = styled(Text)`
  color: ${({ theme }) => (theme.isDark ? '#8D9FB5' : theme.colors.text)};
`

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onUsdtInput?: (value: string) => void
  onMax?: () => void
  onSlideChange?: (value: number) => void
  showMaxButton: boolean
  label?: string
  usdtInput?:string
  onCurrencySelect?: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: Currency | null
  id: string
  showMaxSlide?: boolean
  showCommonBases?: boolean
  enterPage?: string
  isEstimate?: boolean | undefined
  slidePercent?: number | undefined
  expandTokens?: {[address: string] : WrappedTokenInfo}
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  usdtInput,
  onSlideChange,
  slidePercent,
  isEstimate,
  onUsdtInput,
  showMaxButton,
  label,
  showMaxSlide,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases = true,
  enterPage = '',
  expandTokens,
}: CurrencyInputPanelProps) {
  // const [amount, setAmount] = useState(value)
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useActiveWeb3React()
  const { toastSuccess } = useToast()
  // eslint-disable-next-line
  const [percent, setPercent] = useState(0)
  useEffect(() => {
    if (slidePercent) {
      const val = Math.round(Number(slidePercent) * 100) < 100 ? Math.round(Number(slidePercent) * 100) : 100
      setPercent(val)
    } else {
      setPercent(0)
    }
  }, [slidePercent])
  const { decimals = 0, symbol = '', address = '', logoURI = '' } = (currency as WrappedTokenInfo) || {}
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const { t } = useTranslation()
  const translatedLabel = label || t('Input')
  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const handlePop = (e: any) => {
    e.stopPropagation()
  }

  const handleCopy = () => {
    if (copy(address)) {
      toastSuccess(t('Copied'))
    }
  }
  // const debounceValue = useDebounce(amount, 200)
  // useEffect(() => {
  //   onUserInput(debounceValue)
  // }, [debounceValue, onUserInput])

  const handleChangePercent = (sliderPercent: number) => {
    if (onSlideChange) {
      onSlideChange(sliderPercent)
    }
    setPercent(sliderPercent)
  }

  const onMaxSlide = () => {
    if (onMax) {
      onMax()
    }
    setPercent(100)
  }
  useEffect(() => {
    if (currency) {
      setPercent(0)
    }
  }, [currency])

  return (
    <InputPanel id={id}>
      <Container hideInput={hideInput}>
        {!hideInput && (
          <LabelRow>
            <RowBetween>
              <Flex alignItems="center">
                {/* <StyledText fontSize="16px">{translatedLabel}</StyledText> */}
                <CurrencySelect
                  selected={!!currency}
                  className="open-currency-select-button"
                  onClick={() => {
                    if (!disableCurrencySelect) {
                      setModalOpen(true)
                    }
                  }}
                >
                  <Aligner>
                    {pair ? (
                      <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
                    ) : currency ? (
                      <CurrencyLogo currency={currency} expandTokens={expandTokens} size="24px" style={{ marginRight: '8px' }} />
                    ) : null}
                    {pair ? (
                      <Text id="pair">
                        {pair?.token0.symbol}:{pair?.token1.symbol}
                      </Text>
                    ) : (
                      <Text id="pair">
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                              currency.symbol.length - 5,
                              currency.symbol.length
                            )}`
                          : currency?.symbol) || t('Select a currency')}
                      </Text>
                    )}
                    {!disableCurrencySelect && <ChevronDownIcon />}
                  </Aligner>
                </CurrencySelect>
              </Flex>

              {account && (
                <StyledText onClick={onMax} fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                  {!hideBalance && !!currency && selectedCurrencyBalance ? (
                    <IconBoxWrap>
                      <span>
                        {t('Balance')}: {selectedCurrencyBalance?.toSignificant(6)}
                      </span>
                      {enterPage === 'swap_page' && (
                        <IconBoxInnerWrap onClick={(e) => handlePop(e)}>
                          <IconBox onClick={() => registerToken(address, symbol, decimals, logoURI)}>
                            <img src="images/did/metamask.png" alt="" />
                          </IconBox>
                          <IconBox onClick={handleCopy}>
                            <CopyIcon style={{ color: '#4E93EA', height: '16px' }} />
                          </IconBox>
                        </IconBoxInnerWrap>
                      )}
                    </IconBoxWrap>
                  ) : (
                    ' -'
                  )}
                </StyledText>
              )}
            </RowBetween>
          </LabelRow>
        )}
        <InputRow style={hideInput ? { padding: '0', borderRadius: '8px' } : {}} selected={disableCurrencySelect}>
          {!hideInput && (
            <>
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={(val) => {
                  // setAmount(val)
                  onUserInput(val)
                }}
              />
              {/* {currency && <Text>$0.12</Text>} */}
              {account && currency && onUsdtInput &&  (
                <UsdtInputPanel
                  className="token-amount-input"
                  value={usdtInput||''}
                  placeholder="USD"
                  onUsdtInput={(val) => {
                    // setAmount(val)
                    onUsdtInput(val)
                  }}
                />
              )}
            </>
          )}
        </InputRow>
      </Container>
      <Flex>
        <SlideContainer style={{ flex: 1 }}>
          {showMaxSlide && onSlideChange && account && currency && (
            <Slider
              min={0}
              max={100}
              value={percent}
              onValueChanged={handleChangePercent}
              name="stake"
              valueLabel={`${percent}%`}
              step={2}
            />
          )}
        </SlideContainer>
        {account && currency && label !== 'To' && showMaxSlide && (
          <Button onClick={onMaxSlide} scale="sm" variant="text">
            {t('Max')}
          </Button>
        )}
      </Flex>

      {!disableCurrencySelect && onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
        />
      )}
    </InputPanel>
  )
}
