import React, { useEffect,useCallback,useState } from 'react'
import { queryUserInfo,IUserInfo } from 'utils/api'
import { useWeb3React } from '@web3-react/core'

 const useUserAvatar = ()=>{
    const { account } = useWeb3React()
    const MyAccount = account||''
    const [userInfo, setUserInfo] = useState<IUserInfo | null>(null)
    const fetchUserInfo = useCallback(() => {
        queryUserInfo(MyAccount).then(res => {
          if (res.data) {
            setUserInfo(res.data.data);
          }
        })
      }, [MyAccount])

    useEffect(()=>{
        if(MyAccount){
            fetchUserInfo()
          }
    },[MyAccount,fetchUserInfo])
    return userInfo
}

export default useUserAvatar