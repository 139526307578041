import { AxiosPromise } from 'axios'
import request from './request'
// import { IAdderssInfo, IMyBanner, IPiggyBank,bwcJsonInfo, IPiggyBankResult, IQueryScratch, IScrachOffResult, IScratch, IUserPoints, IUserTaskRes } from './api-types'

const HOST = process.env.REACT_APP_API_HOST

// add favToken
export const addOrDelCollectToken = (data) => {
  return request({
    url: `${HOST}/api/addOrDelCollectToken`,
    method: 'POST',
    data
  })
}
export const getTokenTrending = (data) => {
  return request({
    url: `${HOST}/api/queryTokenTrending`,
    method: 'POST',
    data
  }) 
}

// remove favToken
export const queryCollectToken = (data) => {
    return request({
      url: `${HOST}/api/queryCollectToken?address=${data}`,
      method: 'get'
    })
  }

  export interface IUserInfo {
    id: number;
    address: string;
    nickName: string;
    activeSince: string;
    createTime:string;
    userAvatar:any;
    updateNickTime:string
    telegram?:null|string
    discord?:null|string
  }
  
  
  export const queryUserInfo = (userAddress: string) => {
    return request({
      url: `${HOST}/api/queryUserInfo/${userAddress}`,
      method: 'GET',
    }) as AxiosPromise<{data: IUserInfo}>
  }
