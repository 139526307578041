import React from "react";
import { Svg } from "@metaswap/uikit";
import { SvgProps } from "../types";

const AddFaviteIcon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" fill="currentColor"   width="20px" {...props}>
       <path d="M11.5562 0.784421C11.7309 0.405193 12.2691 0.405193 12.4438 0.784422L15.3327 7.05612C15.4039 7.21067 15.5501 7.31709 15.7189 7.33713L22.5682 8.15034C22.9824 8.19951 23.1487 8.71211 22.8425 8.99566L17.7787 13.685C17.6539 13.8005 17.598 13.9727 17.6311 14.1397L18.9753 20.914C19.0566 21.3236 18.6212 21.6404 18.2572 21.4364L12.2387 18.0629C12.0904 17.9797 11.9096 17.9797 11.7613 18.0629L5.74277 21.4364C5.37885 21.6404 4.9434 21.3236 5.02468 20.914L6.36888 14.1397C6.40201 13.9727 6.34614 13.8005 6.22134 13.685L1.1575 8.99566C0.851309 8.71211 1.01763 8.19951 1.43179 8.15034L8.28106 7.33713C8.44985 7.31709 8.59612 7.21067 8.66731 7.05611L11.5562 0.784421Z"/>
       {/* <path d="M11.5562 0.784421C11.7309 0.405193 12.2691 0.405193 12.4438 0.784422L15.3327 7.05612C15.4039 7.21067 15.5501 7.31709 15.7189 7.33713L22.5682 8.15034C22.9824 8.19951 23.1487 8.71211 22.8425 8.99566L17.7787 13.685C17.6539 13.8005 17.598 13.9727 17.6311 14.1397L18.9753 20.914C19.0566 21.3236 18.6212 21.6404 18.2572 21.4364L12.2387 18.0629C12.0904 17.9797 11.9096 17.9797 11.7613 18.0629L5.74277 21.4364C5.37885 21.6404 4.9434 21.3236 5.02468 20.914L6.36888 14.1397C6.40201 13.9727 6.34614 13.8005 6.22134 13.685L1.1575 8.99566C0.851309 8.71211 1.01763 8.19951 1.43179 8.15034L8.28106 7.33713C8.44985 7.31709 8.59612 7.21067 8.66731 7.05611L11.5562 0.784421Z"/> */}
    </Svg>
  );
};

export default AddFaviteIcon;

