import {ethers} from 'ethers'
import { useCallback,useEffect,useState } from 'react';
import {queryCollectToken} from 'utils/api'
import { useActiveWeb3React } from 'hooks'



interface FavListProps{
    decimal:number
    tokenAddress:string
    tokenSymbol:string
  }

const useTokenFavList = ()=>{
    const [favTokenList,setFavTokenList] = useState<FavListProps[]>([])
    const {account} = useActiveWeb3React()
    const fetchTokenList = useCallback(async()=>{
        const list:any = await queryCollectToken(account)
        if(list.code===200&&list.data.list.length>0){
            setFavTokenList(list.data.list)
        }else{
            setFavTokenList([])
        }    
    },[account])
    useEffect(()=>{
        if(account){
            fetchTokenList()
        }
    },[fetchTokenList,account])
    return {favTokenList,fetchTokenList}
}

export default useTokenFavList