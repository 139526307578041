import { ChainId, JSBI, Percent, Token, WETH } from '@metaswap/sdk'

export const CALCULATE_ADDRESS = '0x90055e75685D521fAbB1eC567d1FBC63fC5E01D3'
export const ROUTER_ADDRESS = '0x8317c460C22A9958c27b4B6403b98d2Ef4E2ad32'
export const SMART_ROUTER_ADDRSS = '0x8317c460C22A9958c27b4B6403b98d2Ef4E2ad32'
export const ROUTER_ADDRESS_TOY = '0x8317c460C22A9958c27b4B6403b98d2Ef4E2ad32'
export const TRADE_MINING_ADDRESS = '0x5c9f1A9CeD41cCC5DcecDa5AFC317b72f1e49636' // 交易挖矿合约

export const SWAP_MASTERCHEF_ADDRESS = '0xdfAa0e08e357dB0153927C7EaBB492d1F60aC730'// Farm的masterchef
export const MULTICALL_ADDRESS = '0x1ee38d535d541c55c9dae27b12edf090c608e6fb' // Multilcall合约 注没有使用

export const TOYGOGE_ADDRESS = '0x6205eBd05dE85430b7B54E662d2dCB3bA53b12b6'
export const BABY_ADDRESS = '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657'
export const USDT_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'
export const BABY_USDT_ADDRESS= '0xE730C7B7470447AD4886c763247012DfD233bAfF'
export const CALC_PRICE_ADDRESS = '0x097085b811ec8f7949A13BF315b98298A5EeC616'

const ROUTE_LIQUIDITY = 0.01
export const ROUTE_LIQUIDITY_OUT = 1 - ROUTE_LIQUIDITY
export const ROUTE_LIQUIDITY_IN = 1 + ROUTE_LIQUIDITY

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

interface slippage {
  symbol: string
  value: number
}

export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const BABY = new Token(ChainId.MAINNET, '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657', 18, 'BABY', 'BABY USD')
// // TODO 记得上线使用上面地址
// export const USDT = new Token(ChainId.BSCTESTNET, '0x1bC64Fa1104C96e03F9F06483B64ab6251a8E9E2', 18, 'USDT', 'Tether USD')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token'
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  // ...WETH_ONLY,
  [ChainId.BSCTESTNET]: [...WETH_ONLY[ChainId.BSCTESTNET], USDT],
  [ChainId.MAINNET]: [USDT],
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {},
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [BABY],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB, USDT],
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18, 'BABY', 'BabySwap Token'),
      new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB'),
    ],
    [BUSD, USDT],
    [DAI, USDT],
  ],
}

export const NetworkContextName = 'NETWORK'

// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 500
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
// 输入框默认选中币种
export const SELECT_COIN_ADDRESS = '0x55d398326f99059fF775485246999027B3197955'

// one basis point
export const ONE_BIPS = new Percent(JSBI.BigInt(1), JSBI.BigInt(10000))
export const BIPS_BASE = JSBI.BigInt(10000)
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(JSBI.BigInt(100), BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(JSBI.BigInt(300), BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(JSBI.BigInt(500), BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(JSBI.BigInt(1000), BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(JSBI.BigInt(1500), BIPS_BASE) // 15%

// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH

export const DEFAULT_MIN_SLIPPAGE: slippage[] = [
  {symbol: 'TOYDOGE', value: 1500}
]

// SDN OFAC addresses
export const BLOCKED_ADDRESSES: string[] = [
  '0x118203b0f2a3ef9e749d871c8fef5e5e55ef5c91',
  '0x47a344588653efa88eb6d58433b6a2a5e202d65d'
]

// config token
export const LUCK_TRADE_TOKNE = ['0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657', '0x1f9655c660B915b4344b71e2A6d3155C57AD1bB6','0x6c4d9bb6be7dbb88ce3e0e7b6848e306e03fec55']
export const BABY_FACTORY = ['0x86407bEa2078ea5f5EB5A52B2caA963bC1F889Da']
