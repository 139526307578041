import React from 'react'
import styled from 'styled-components'
import { Flex, Text, HelpIcon, useTooltip, Box, Placement } from '@metaswap/uikit'

const StyledText = styled(Text)`
  color: ${({ theme }) => theme.isDark ? '#8D9FB5' : theme.colors.text};
`

const TipTitle: React.FC<{ title: string; content: React.ReactNode; placement?: Placement }> = ({ title, content, placement = 'top' }) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(content, {
    placement,
  })

  return (
    <Flex alignItems="center">
      <StyledText fontSize="14px" color="text">
        {title}
      </StyledText>
      {tooltipVisible && tooltip}
      <Box ref={targetRef}>
        <HelpIcon ml="4px" pt="2px" cursor="pointer" width="20px" height="20px" color="textSubtle" />
      </Box>
    </Flex>
  )
}

export default TipTitle
