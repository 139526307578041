import React, { useMemo } from 'react'
import { Text } from '@metaswap/uikit'
import { ChainId, Currency, currencyEquals, ETHER, Token } from '@metaswap/sdk'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { SUGGESTED_BASES } from '../../constants'
import { AutoColumn } from '../Column'
import TipTitle from '../QuestionHelper'
import { AutoRow } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { CommonTokenWrapper } from './styleds'


interface FavListProps{
  decimal:number
  tokenAddress:string
  tokenSymbol:string
}
const BaseWrapper = styled.div<{ disable?: boolean }>`
  border: 1px solid ${({ theme, disable }) => (disable ? 'transparent' : theme.colors.tertiary)};
  border-radius: 10px;
  display: flex;
  padding: 6px;

  align-items: center;
  :hover {
    /* cursor: ${({ disable }) => !disable && 'pointer'}; */
    cursor: 'pointer';
    background-color: ${({ theme, disable }) => (!disable && theme.isDark ? 'rgb(8, 6, 11)' : 'rgb(250, 249, 250)')};
  }
  background-color: ${({ theme, disable }) => disable && theme.colors.tertiary};
  opacity: ${({ disable }) => disable && '0.4'};
`

export default function CommonBases({
  chainId,
  onSelect,
  selectedCurrency,
  favTokenList,
  otherCurrency,
}: {
  chainId?: ChainId
  favTokenList:FavListProps[]
  selectedCurrency?: Currency | null
  otherCurrency?: Currency | null
  onSelect: (currency: Currency) => void
}) {
  const { t } = useTranslation()
  const hasBnb = useMemo(() => {
    return favTokenList.find((e) => e.tokenSymbol === 'BNB')
  }, [favTokenList])

  const favListNoBNB = useMemo(() => {
    return favTokenList.filter((e) => e.tokenSymbol !== 'BNB')
  }, [favTokenList])

  // console.info( SUGGESTED_BASES[56].slice(0, SUGGESTED_BASES[56].length - 1))

  return (
    <AutoColumn gap="md">
      <AutoRow>
        <TipTitle
          title={t('Favorite Tokens')}
          content={t('Add your favorite & most used tokens here for quick access.')}
        />
      </AutoRow>
      <CommonTokenWrapper>
        {(chainId ? SUGGESTED_BASES[chainId] : []).map((token: Token) => {
          const selected =
            (selectedCurrency instanceof Token && selectedCurrency.address === token.address) ||
            (otherCurrency instanceof Token && otherCurrency.address === token.address)
          return (
            <BaseWrapper onClick={() => onSelect(token)} disable={selected} key={token.address}>
              <CurrencyLogo currency={token} style={{ marginRight: 8 }} />
              <Text>{token.symbol}</Text>
            </BaseWrapper>
          )
        })}
        {hasBnb && (
          <BaseWrapper
            onClick={() => {
              if (!selectedCurrency || !currencyEquals(selectedCurrency, ETHER)) {
                onSelect(ETHER)
              }
            }}
            disable={selectedCurrency === ETHER || otherCurrency === ETHER}
          >
            <CurrencyLogo currency={ETHER} style={{ marginRight: 8 }} />
            <Text>BNB</Text>
          </BaseWrapper>
        )}
        {(chainId ? favListNoBNB : []).map((token: any) => {
          const newToken =  new Token(56,token.tokenAddress,token.decimal,token.tokenSymbol,token.tokenSymbol) 
          const selected =
            (selectedCurrency instanceof Token && selectedCurrency.address === newToken.address) ||
            (otherCurrency instanceof Token && otherCurrency.address === token.address)
          return (
            <BaseWrapper onClick={() => onSelect(newToken)} disable={selected} key={newToken.address}>
              <CurrencyLogo currency={newToken} style={{ marginRight: 8 }} />
              <Text>{newToken.symbol}</Text>
            </BaseWrapper>
          )
        })}
      </CommonTokenWrapper>
    </AutoColumn>
  )
}
