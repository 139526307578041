import React ,{useEffect,useState}from 'react'
import { TopMenu as UikitMenu, useModal } from '@metaswap/uikit'
import { useWeb3React } from '@web3-react/core'
import ClaimRewardModal from 'components/ClaimRewardModal'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
// import useGetPriceData from 'hooks/useGetPriceData'
// import useGetLocalProfile from 'hooks/useGetLocalProfile'
import PhishingWarningBanner from 'components/PhishingWarningBanner'
import { usePhishingBannerManager } from 'state/user/hooks/index'
import { languageList } from 'config/localization/languages'
import useBabyPrice from 'hooks/useBabyPrice'
import useSpaceId from 'hooks/useSpaceId'
import useAuth from 'hooks/useAuth'
import {switchNetwork} from 'utils/wallet'
import useUserAvatar from './hooks/useGetUserAvatr'
import ModalInfo,{whiteList} from './ModalConfig'


const Menu: React.FC = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const spaceName = useSpaceId()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { isDark, toggleTheme } = useTheme()
  const babyPrice = useBabyPrice()
  const userInfo = useUserAvatar()
  const [showPhishingWarningBanner] = usePhishingBannerManager()
  const {contentInfo,title} = ModalInfo
  const [chainId,setChainId] = useState(56)
 const confirmClaim = () => {
  window.localStorage.setItem('MayCliam06', '11')
    const domain = window.location.host.split(".").reverse()[0];
    const hrefIn = 'https://home.babyswap.$$/claim';
    const href = domain.indexOf("localhost") > -1 ? hrefIn?.replace("$$", "finance") : hrefIn?.replace("$$", domain);
    window.location.href = href
  }
  const onDismiss = () => {
    // console.info(222)
  }
  const [openActivity] = useModal(<ClaimRewardModal onDismiss={onDismiss} onConfirm={confirmClaim} claimInfo={contentInfo} title={title} />);
  const connectorId = window.localStorage.getItem('MayCliam06')
  useEffect(()=>{
   
    if(account&&!connectorId){
      const a = whiteList.includes(account.toLocaleLowerCase())
      if(a){
        openActivity()
        window.localStorage.setItem('MayCliam06','11')
      }
    }
 const id = (window as WindowChain).ethereum?.chainId
if(id){
  setChainId(parseInt(id))
}

// eslint-disable-next-line react-hooks/exhaustive-deps
},[account,connectorId,(window as WindowChain).ethereum?.chainId])
  const entryDid = ()=>{
    let link = 'https://home.babyswap.$$/did'
    const domain = window.location.host.split(".").reverse()[0];
    link = domain.indexOf("localhost") > -1 ? link?.replace("$$", "finance") : link?.replace("$$", domain);
    window.location.replace(link)
  }

  const HandleUserSwitchNetWork = async()=>{
    try {
      await switchNetwork()
    } catch (error) {
      console.info(error)
    }
    
  }
  return (
    <UikitMenu
      links={[]}
      account={spaceName||account as string}
      login={login}
      logout={logout}
      isDark={isDark}
      switchNetWork={HandleUserSwitchNetWork}
      showWarnChain={chainId!==56}
      openActivity={openActivity}
      entryDid={entryDid}
      banner={showPhishingWarningBanner && typeof window !== 'undefined' ? <PhishingWarningBanner /> : undefined}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage && currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      didAvtar ={userInfo?.userAvatar}
      t={t}
      cakePriceUsd={babyPrice}
      // profile={profile}
      {...props}
    />
  )
}

export default Menu
