export const DEFAULT_TOKEN_LIST_URL = 'babyswap'
export const CoinGecko_URL = 'https://token.babyswap.finance/tokens/coingecko.json'
export const CoinMarketCap_URL  = 'https://token.babyswap.finance/tokens/cmc.json'

export const EXPAND_LIST_OF_LISTS: string[] = [
    CoinGecko_URL,
    CoinMarketCap_URL,
]

export const DEFAULT_LIST_OF_LISTS: string[] = [
    DEFAULT_TOKEN_LIST_URL,
    ...EXPAND_LIST_OF_LISTS,
]

