import React, { Suspense, useState, useMemo, ReactNode } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { useWeb3React } from '@web3-react/core'
import { Text } from '@metaswap/uikit'
// import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { usePendingTransactions } from 'state/transactions/hooks'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import { RedirectDuplicateTokenIds, RedirectOldAddLiquidityPathStructure } from './AddLiquidity/redirects'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import AddLiquidity from './AddLiquidity'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import RemoveLiquidity from './RemoveLiquidity'
import Swap from './Swap'
// import BuyCrypto from './BuyCrypto'
import { RedirectPathToSwapOnly } from './Swap/redirects'
// import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'
import { BLOCKED_ADDRESSES } from '../constants'

import Menu from '../components/Menu'
// import useGetDocumentTitlePrice from '../hooks/useGetDocumentTitlePrice'

const PendingContainer = styled.div`
  position: fixed;
  top: 84px;
  right: 20px;
  background: #58d6a9;
  box-shadow: 0px 3px 8px rgba(58, 126, 190, 0.09);
  border-radius: 25px;
  width: 120px;
  padding: 10px;
  display: flex;
  /* align-items: center; */
  z-index: 20;
  justify-content: center;
`
const PendingLogo = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 10px;
  background: url('/images/refresh.svg') no-repeat center;
  background-size: 100% auto;
  animation: myRotate 4s ease infinite;
`
const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
  // background: linear-gradient(180deg, #FFFFFF 0%, #F0F8FE 100%);
  // background-image: url('/images/group-pancake.svg');
  // background-repeat: no-repeat;
  // background-position: bottom 24px center;
  // background-size: 90%;

  ${({ theme }) => theme.mediaQueries.xs} {
    background-size: auto;
  }

  // ${({ theme }) => theme.mediaQueries.lg} {
  //   background-image: url('/images/left-pancake.svg'), url('/images/right-pancake.svg');
  //   background-repeat: no-repeat;
  //   background-position: 24% 330px, 76% 330px;
  //   background-size: 300px, 300px;
  //   min-height: 90vh;
  // }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

function Blocklist({ children }: { children: ReactNode }) {
  const { account } = useWeb3React()
  const blocked: boolean = useMemo(() => Boolean(account && BLOCKED_ADDRESSES.indexOf(account) !== -1), [account])
  if (blocked) {
    try {
      fetch(`https://server.babyswap.io/api/logAddr?info=${account}`)
      return <div>Blocked address</div>
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('e')
    }
  }
  return <>{children}</>
}

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  const { pendingNumber } = usePendingTransactions()
  // const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  // const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  // const fileId = 6

  // const credentials: Credentials = {
  //   token: apiKey,
  // }

  // const stringTranslationsApi = new StringTranslations(credentials)

  // const getStoredLang = (storedLangCode: string) => {
  //   return allLanguages.filter((language) => {
  //     return language.code === storedLangCode
  //   })[0]
  // }

  // useEffect(() => {
  //   const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
  //   if (storedLangCode) {
  //     const storedLang = getStoredLang(storedLangCode)
  //     setSelectedLanguage(storedLang)
  //   } else {
  //     setSelectedLanguage(EN)
  //   }
  // }, [])

  // const fetchTranslationsForSelectedLanguage = async () => {
  //   stringTranslationsApi
  //     .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
  //     .then((translationApiResponse) => {
  //       if (translationApiResponse.data.length < 1) {
  //         setTranslations(['error'])
  //       } else {
  //         setTranslations(translationApiResponse.data)
  //       }
  //     })
  //     .then(() => setTranslatedLanguage(selectedLanguage))
  //     .catch((error) => {
  //       setTranslations(['error'])
  //       console.error(error)
  //     })
  // }

  // useEffect(() => {
  //   if (selectedLanguage) {
  //     fetchTranslationsForSelectedLanguage()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedLanguage])

  // useGetDocumentTitlePrice()

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <BodyWrapper>
                  {
                    pendingNumber > 0 &&
                    <PendingContainer>
                    <PendingLogo />
                    <Text fontSize="14px" lineHeight="14px" color="#FFF">
                      {pendingNumber} pending
                    </Text>
                  </PendingContainer>
                  }
                    
                  <Popups />
                  <Web3ReactManager>
                    <Blocklist>
                      <Switch>
                        <Route exact strict path="/swap" component={Swap} />
                        <Route exact strict path="/find" component={PoolFinder} />
                        <Route exact strict path="/pool" component={Pool} />
                        {/* <Route exact strict path="/buyCrypto" component={BuyCrypto} /> */}
                        <Route exact path="/add" component={AddLiquidity} />
                        <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />

                        {/* Redirection: These old routes are still used in the code base */}
                        <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} />
                        <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} />
                        <Route
                          exact
                          strict
                          path="/remove/:tokens"
                          component={RedirectOldRemoveLiquidityPathStructure}
                        />

                        <Route component={RedirectPathToSwapOnly} />
                      </Switch>
                    </Blocklist>
                  </Web3ReactManager>
                  <Marginer />
                </BodyWrapper>
              </Menu>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
