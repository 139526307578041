import { Currency, CurrencyAmount, Pair, Token, Trade } from '@metaswap/sdk'
// import flatMap from 'lodash.flatmap'
import { useMemo } from 'react'
import { Field } from 'state/swap/actions'

// import { BASES_TO_CHECK_TRADES_AGAINST, CUSTOM_BASES } from '../constants'
import { PairState, useNewPairs } from '../data/Reserves'
import { wrappedCurrency } from '../utils/wrappedCurrency'
import { useSelectedTokenList } from '../state/lists/hooks'
import { useActiveWeb3React } from './index'
import useCalculateRoute from './useCalculateRoute'
import { useToken } from './Tokens'

interface ITradeExact {
  trade: Trade | null
  fees: string[]
  factories: string[]
  amounts: string[]
  pairs: string[]
  paths: string[]
}

// function useAllCommonPairs(currencyA?: Currency, currencyB?: Currency): Pair[] {
//   const { chainId } = useActiveWeb3React()

//   // Base tokens for building intermediary trading routes
//   const bases: Token[] = useMemo(() => (chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : []), [chainId])

//   // All pairs from base tokens
//   const basePairs: [Token, Token][] = useMemo(
//     () =>
//       flatMap(bases, (base): [Token, Token][] => bases.map((otherBase) => [base, otherBase])).filter(
//         ([t0, t1]) => t0.address !== t1.address
//       ),
//     [bases]
//   )

//   const [tokenA, tokenB] = chainId
//     ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
//     : [undefined, undefined]
//   const allPairCombinations: [Token, Token][] = useMemo(
//     () =>
//       tokenA && tokenB
//         ? [
//             // the direct pair
//             [tokenA, tokenB],
//             // token A against all bases
//             ...bases.map((base): [Token, Token] => [tokenA, base]),
//             // token B against all bases
//             ...bases.map((base): [Token, Token] => [tokenB, base]),
//             // each base against all bases
//             ...basePairs,
//           ]
//             .filter((tokens): tokens is [Token, Token] => Boolean(tokens[0] && tokens[1]))
//             .filter(([t0, t1]) => t0.address !== t1.address)
//             // This filter will remove all the pairs that are not supported by the CUSTOM_BASES settings
//             // This option is currently not used on Pancake swap
//             .filter(([t0, t1]) => {
//               if (!chainId) return true
//               const customBases = CUSTOM_BASES[chainId]
//               if (!customBases) return true

//               const customBasesA: Token[] | undefined = customBases[t0.address]
//               const customBasesB: Token[] | undefined = customBases[t1.address]

//               if (!customBasesA && !customBasesB) return true
//               if (customBasesA && !customBasesA.find((base) => t1.equals(base))) return false
//               if (customBasesB && !customBasesB.find((base) => t0.equals(base))) return false

//               return true
//             })
//         : [],
//     [tokenA, tokenB, bases, basePairs, chainId]
//   )
//   const allPairs = usePairs(allPairCombinations)

//   // only pass along valid pairs, non-duplicated pairs
//   return useMemo(
//     () =>
//       Object.values(
//         allPairs
//           // filter out invalid pairs
//           .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
//           // filter out duplicated pairs
//           .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
//             memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
//             return memo
//           }, {})
//       ),
//     [allPairs]
//   )
// }

export function useCalculatePath (currencyA?: Currency, currencyB?: Currency, field?: Field, typedValue?: string) {
  const { chainId } = useActiveWeb3React()
  const allTokens = useSelectedTokenList()
  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]
  const { calculateRoute } = useCalculateRoute(tokenA, tokenB, field, typedValue)
  
  const amounts = useMemo(() => calculateRoute?.amounts ?? [], [calculateRoute])
  const paths = useMemo(() => calculateRoute?.path ?? [], [calculateRoute])
  const factories = useMemo(() => calculateRoute?.factories ?? [], [calculateRoute])
  const fees = useMemo(() => calculateRoute?.fees.map(d => d.toString()) ?? [], [calculateRoute])
  const pairAddresses = useMemo(() => calculateRoute?.pairs ?? [], [calculateRoute])
  const allTokenList = useMemo(() => allTokens[chainId ?? 56], [allTokens, chainId])
  const findAddAddress = useMemo(() => {
    return calculateRoute?.path.filter(f => !allTokenList[f]) ?? []
  }, [calculateRoute, allTokenList])
  const addToken = useToken(findAddAddress[0])
  const allPairCombinations:[Token, Token][] = useMemo(() => {
    if (calculateRoute) {
      const combinations: [Token, Token][] = []
      for (let i = 0; i < calculateRoute?.path.length - 1; i++) {
        const token = calculateRoute?.path[i]
        const netToken = calculateRoute?.path[i+1]
        const leftToken = addToken && token.toLocaleLowerCase() === findAddAddress[0].toLocaleLowerCase() ? addToken : allTokenList[token]
        const rightToken = addToken && netToken.toLocaleLowerCase() === findAddAddress[0].toLocaleLowerCase() ? addToken : allTokenList[netToken]
        combinations.push([leftToken, rightToken])
      }
      return combinations
    }
    return []
  }, [calculateRoute, allTokenList, findAddAddress, addToken])
  const allPairs = useNewPairs(allPairCombinations, pairAddresses)

  const allowedPairs = useMemo(
    () =>
      Object.values(
        allPairs
          // filter out invalid pairs
          .filter((result): result is [PairState.EXISTS, Pair] => Boolean(result[0] === PairState.EXISTS && result[1]))
          // filter out duplicated pairs
          .reduce<{ [pairAddress: string]: Pair }>((memo, [, curr]) => {
            memo[curr.liquidityToken.address] = memo[curr.liquidityToken.address] ?? curr
            return memo
          }, {})
      ),
    [allPairs]
  )
  return { factories, amounts, pairs: pairAddresses, paths, fees, allowedPairs }
}

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTradeExactIn(currencyAmountIn?: CurrencyAmount, currencyOut?: Currency, field?: Field, typedValue?: string):ITradeExact {
  // const allowedPairs = useAllCommonPairs(currencyAmountIn?.currency, currencyOut)
  const { allowedPairs, fees, factories, amounts, pairs, paths } = useCalculatePath(currencyAmountIn?.currency, currencyOut, field, typedValue)

  return useMemo(() => {
    if (currencyAmountIn && currencyOut && allowedPairs.length > 0) {
      return {
        trade: Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, currencyOut, { maxHops: 5, maxNumResults: 1 })[0] ?? null,
        fees,
        factories,
        amounts,
        pairs,
        paths
      }
    }
    return {
      trade: null,
      fees: [],
      factories: [],
      amounts: [],
      pairs: [],
      paths: []
    }
  }, [allowedPairs, currencyAmountIn, currencyOut, fees, factories, amounts, pairs, paths])
}

/**
 * Returns the best trade for the token in to the exact amount of token out
 */
export function useTradeExactOut(currencyIn?: Currency, currencyAmountOut?: CurrencyAmount, field?: Field, typedValue?: string):ITradeExact {
  // const allowedPairs = useAllCommonPairs(currencyIn, currencyAmountOut?.currency)
  
  const { allowedPairs, fees, factories, amounts, pairs, paths } = useCalculatePath(currencyIn, currencyAmountOut?.currency, field, typedValue)

  return useMemo(() => {
    if (currencyIn && currencyAmountOut && allowedPairs.length > 0) {
      return {
        trade: Trade.bestTradeExactOut(allowedPairs, currencyIn, currencyAmountOut, { maxHops: 5, maxNumResults: 1 })[0] ?? null,
        fees,
        factories,
        amounts,
        pairs,
        paths
      }
    }
    return {
      trade: null,
      fees: [],
      factories: [],
      amounts: [],
      pairs: [],
      paths: []
    }
  }, [allowedPairs, currencyIn, currencyAmountOut, fees, factories, amounts, pairs, paths])
}
