import { Currency, ETHER, Token } from '@metaswap/sdk'
import React, { KeyboardEvent, RefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Text, CloseIcon } from '@metaswap/uikit'
import { useSelector } from 'react-redux'
import { FixedSizeList } from 'react-window'
import { ThemeContext } from 'styled-components'
import useTokenFavList from 'hooks/useTokenFavList'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useTranslation } from 'contexts/Localization'
import { useActiveWeb3React } from '../../hooks'
import { AppState } from '../../state'
import { useAllTokens, useToken, useExpendTokens } from '../../hooks/Tokens'
import {  useSelectedListInfo } from '../../state/lists/hooks'
// import { listToTokenMap, useSelectedListInfo } from '../../state/lists/hooks'
// import { useCgListUrl, useCmcListUrl, } from '../../state/otherLists/hooks'
import { LinkStyledButton } from '../Shared'
import { isAddress } from '../../utils'
import Card from '../Card'
import Column from '../Column'
import ListLogo from '../ListLogo'
import TipTitle from '../QuestionHelper'
import Row, { RowBetween, PoopBetween } from '../Row'
import CommonBases from './CommonBases'
import CurrencyList from './CurrencyList'
import { filterTokens } from './filtering'
import SortButton from './SortButton'
import { useTokenComparator } from './sorting'
import { PaddingColumn, SearchInput, Separator} from './styleds'

interface CurrencySearchProps {
  isOpen: boolean
  onDismiss: () => void
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherSelectedCurrency?: Currency | null
  showCommonBases?: boolean
  onChangeList: () => void
}

interface OtherToken {
  [address: string]: any
}

interface newToken extends  Token {
  isFav?:boolean
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  onDismiss,
  isOpen,
  onChangeList,
}: CurrencySearchProps) {
  const { t } = useTranslation()
  const { chainId } = useActiveWeb3React()
  const theme = useContext(ThemeContext)
  const fixedList = useRef<FixedSizeList>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false)
  const allTokens = useAllTokens()
  const expandTokens = useExpendTokens()
  const otherTokens = {} as OtherToken
  // if they input an address, use it
  const { favTokenList,fetchTokenList } = useTokenFavList()
  const isAddressSearch = isAddress(searchQuery)
  const searchToken = useToken(searchQuery)
  const updateTokenList = async()=>{
    await fetchTokenList()
  }
  const showETH: boolean = useMemo(() => {
    const s = searchQuery.toLowerCase().trim()
    return s === '' || s === 'b' || s === 'bn' || s === 'bnb'
  }, [searchQuery])

  const tokenComparator = useTokenComparator(invertSearchOrder)

  const audioPlay = useSelector<AppState, AppState['user']['audioPlay']>((state) => state.user.audioPlay)
  const filteredTokens: Token[] = useMemo(() => {
    if (isAddressSearch) {
      const expandToken: Token | undefined = searchQuery ? expandTokens[searchQuery] : undefined
      return (searchToken && !expandToken) ? [searchToken] : []
    }
    return filterTokens([...Object.values(allTokens)], searchQuery)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressSearch, searchToken, allTokens, searchQuery])

  const filteredExpandTokens: Token[] = useMemo(() => {
    if (searchQuery === '') return []
    if (isAddressSearch) {
      const expandToken: Token | undefined = searchQuery ? expandTokens[searchQuery] : undefined
      return expandToken ? [expandToken] : []
    } 
    // if (isAddressSearch) return searchToken ? [searchToken] : []
    return filterTokens([...Object.values(expandTokens)], searchQuery)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddressSearch, searchToken, expandTokens, searchQuery])

  const filteredSortedTokens: newToken[] = useMemo(() => {
    if (searchToken) return [searchToken]
    
    const sorted = filteredTokens.sort(tokenComparator)
    const symbolMatch = searchQuery
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0)
    if (symbolMatch.length > 1) return sorted
    
    return [
      ...(searchToken ? [searchToken] : []),
      // sort any exact symbol matches first
      ...sorted.filter((token) => token.symbol?.toLowerCase() === symbolMatch[0]),
      ...sorted.filter((token) => token.symbol?.toLowerCase() !== symbolMatch[0]),
      ...Object.values(otherTokens).filter((token) => token.symbol?.toLowerCase() === symbolMatch[0])
    ]
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredTokens, searchQuery, searchToken, tokenComparator])

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency)
      onDismiss()
      if (audioPlay) {
        const audio = document.getElementById('bgMusic') as HTMLAudioElement
        if (audio) {
          audio.play()
        }
      }
    },
    [onDismiss, onCurrencySelect, audioPlay]
  )


    // const finnaTokenList = useMemo(()=>{
     
    //   return filteredSortedTokens.unshift(...filteredSortedTokens.splice(filteredSortedTokens.findIndex(i=>i.symbol==='BABY'),1))
      
    // },[filteredSortedTokens])
    // console.info(finnaTokenList,'filteredSortedTokens',filteredSortedTokens)

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery('')
  }, [isOpen])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback((event) => {
    const input = event.target.value
    const checksummedInput = isAddress(input)
    setSearchQuery(checksummedInput || input)
    fixedList.current?.scrollTo(0)
  }, [])

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        const s = searchQuery.toLowerCase().trim()
        if (s === 'bnb') {
          handleCurrencySelect(ETHER)
        } else if (filteredSortedTokens.length > 0) {
          if (
            filteredSortedTokens[0].symbol?.toLowerCase() === searchQuery.trim().toLowerCase() ||
            filteredSortedTokens.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokens[0])
          }
        }
      }
    },
    [filteredSortedTokens, handleCurrencySelect, searchQuery]
  )

  const selectedListInfo = useSelectedListInfo()

  return (
    <Column style={{ width: '100%', flex: '1 1' }}>
      <PaddingColumn>
        <RowBetween>
          <TipTitle
            title={t('Select a Token')}
            content={t('Find a token by searching for its name or symbol or by pasting its address below.')}
          />
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={t('Search name or paste address')}
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
          onKeyDown={handleEnter}
        />
        {showCommonBases && (
          <CommonBases chainId={chainId} favTokenList={favTokenList} onSelect={handleCurrencySelect} selectedCurrency={selectedCurrency} otherCurrency={otherSelectedCurrency} />
        )}
        <RowBetween>
          <Text fontSize="14px">{t('Token List')}</Text>
          <SortButton ascending={invertSearchOrder} toggleSortOrder={() => setInvertSearchOrder((iso) => !iso)} />
        </RowBetween>
        <PoopBetween>
          <a href="https://app.poop.fi/recycle" target='_blank' rel="noreferrer">
            <img src="./images/poop_recyle.png" style={{width: '100%'}} alt="" />
          </a>
        </PoopBetween>
      </PaddingColumn>
      <Separator />
      <div style={{ flex: '1' }}>
        <AutoSizer disableWidth>
          {({ height }) => (
            <CurrencyList
              height={height}
              showETH={showETH}
              favTokenList={favTokenList||[]}
              currencies={filteredSortedTokens}
              expandCurrencies={filteredExpandTokens}
              onCurrencySelect={handleCurrencySelect}
              onUpdate={updateTokenList}
              otherCurrency={otherSelectedCurrency}
              selectedCurrency={selectedCurrency}
              fixedListRef={fixedList}
            />
          )}
        </AutoSizer>
      </div>

      {null && (
        <>
          <Separator />
          <Card>
            <RowBetween>
              {selectedListInfo.current ? (
                <Row>
                  {selectedListInfo.current.logoURI ? (
                    <ListLogo
                      style={{ marginRight: 12 }}
                      logoURI={selectedListInfo.current.logoURI}
                      alt={`${selectedListInfo.current.name} list logo`}
                    />
                  ) : null}
                  <Text id="currency-search-selected-list-name">{selectedListInfo.current.name}</Text>
                </Row>
              ) : null}
              <LinkStyledButton
                style={{ fontWeight: 500, color: theme.colors.textSubtle, fontSize: 16 }}
                onClick={onChangeList}
                id="currency-search-change-list-button"
              >
                {selectedListInfo.current ? t('Change') : t('Select a list')}
              </LinkStyledButton>
            </RowBetween>
          </Card>
        </>
      )}
    </Column>
  )
}

export default CurrencySearch
