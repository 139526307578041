import React from 'react'
import { Trade, TradeType } from '@metaswap/sdk'
import { Card, CardBody, Text } from '@metaswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { useTradeReward } from 'hooks/useTradeMining'
import { } from 'utils/'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import { AutoColumn } from '../Column'
import TipTitle from '../QuestionHelper'
import { RowBetween, RowFixed } from '../Row'
import FormattedPriceImpact from './FormattedPriceImpact'
import { SectionBreak } from './styleds'
import SwapRoute from './SwapRoute'

function TradeSummary({ trade, allowedSlippage, amounts, pairs, factories, paths }: { 
  trade: Trade
  allowedSlippage: number
  amounts: string[]
  pairs: string[]
  factories: string[]
  paths: string[]
}) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { t } = useTranslation()
  const { reward } = useTradeReward(amounts, pairs, factories, paths)

  return (
    <Card style={{overflow: 'initial'}}>
      <CardBody>
        <RowBetween>
          <RowFixed>
            <TipTitle
              placement='bottom'
              title={isExactIn ? t('Minimum received') : t('Maximum sold')}
              content={t(
                'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.'
              )}
            />
          </RowFixed>
          <RowFixed>
            <Text fontSize="14px">
              {isExactIn
                ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toSignificant(4)} ${trade.outputAmount.currency.symbol}` ??
                  '-'
                : `${slippageAdjustedAmounts[Field.INPUT]?.toSignificant(4)} ${trade.inputAmount.currency.symbol}` ??
                  '-'}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowBetween>
          <RowFixed>
            <TipTitle
              placement='bottom'
              title={t('Price Impact')}
              content={t('The difference between the market price and estimated price due to trade size.')}
            />
          </RowFixed>
          <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
        </RowBetween>

        <RowBetween>
          <RowFixed>
            <TipTitle
              title={t('Liquidity Provider Fee')}
              placement='top'
              content={t(
                'For each trade, a 0.3% fee is paid: 0.15% to LP token holders, 0.05% to buyback BABY and burn, 0.05% to buyback BABY and distribute to vBABY holders, and 0.05% as USDT rewards to vBABY holders.'
              )}
            />
          </RowFixed>
          <Text fontSize="14px">
            {realizedLPFee ? `${realizedLPFee.toSignificant(4)} ${trade.inputAmount.currency.symbol}` : '-'}
          </Text>
        </RowBetween>
        {/* <RowBetween>
          <RowFixed>
            <TipTitle
              title={t('Trading Reward')}
              placement='top'
              content={t('The amount of BABY tokens you will get after a swap.')}
            />
          </RowFixed>
          <Text fontSize="14px">
            {reward ? `${reward} BABY` : '-'} */}
            {/* {rewardUsdt ? `  ~${rewardUsdt} USDT` : '-'} */}
          {/* </Text>
        </RowBetween> */}
      </CardBody>
    </Card>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade,
  amounts?: string[]
  factories?: string[]
  pairs?: string[]
  paths?: string[]
}

export function AdvancedSwapDetails({ trade, factories, pairs, amounts, paths }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()
  const { t } = useTranslation()
  const showRoute = Boolean(trade && trade.route.path.length > 2)

  return (
    <AutoColumn gap="md">
      {trade && (
        <>
          <TradeSummary
            trade={trade}
            factories={factories ?? []}
            pairs={pairs ?? []}
            amounts={amounts ?? []}
            paths={paths ?? []}
            allowedSlippage={allowedSlippage}
          />
          {showRoute && (
            <>
              <SectionBreak />
              <AutoColumn style={{ padding: '0 24px' }}>
                <RowFixed>
                  <Text fontSize="14px">Route</Text>
                  <TipTitle
                    title={t('Route')}
                    content={t('Routing through these tokens resulted in the best price for your trade.')}
                  />
                </RowFixed>
                <SwapRoute trade={trade} />
              </AutoColumn>
            </>
          )}
        </>
      )}
    </AutoColumn>
  )
}
